import { Avatar, Button, List, Modal, Tag, Tooltip, Typography, Upload } from 'antd'
import React, { useEffect, useState } from 'react'
import moment from 'moment';
import GroupsIcon from '@mui/icons-material/Groups';
import { UserOutlined } from '@ant-design/icons';
import { casePriorityData, caseResolveCaseData, caseStatusData } from '../../../share/CaseSystem';
import { updateCase } from '../service';
import SymptonGoalTracker from './sympton-goal-tracker.component';
import { useSelector } from 'react-redux';
import { getAppointments } from '../../patient/service';

const ViewCaseModal = ({ modalOpen, setModalOpen, fetchPetientRequest, all, caseRecord, caseTypes }) => {
    let currentPatient = useSelector((state) => state.currentPatient);

    const defaultCaseValue = {
        type: "",
        priority: "",
        message: "",
        note: "",
        resolveCase: "",
        status: "new",
        cc: [],
        files: [],
        appointmentId: "",
        symptomsAndGoalsTracker: {
            sleep: "None",
            weight: "None",
            energy: "None",
            sexual: "None",
            cognitive: "None",
            healing: "None"
        }
    }

    const [imagePreviewModal, setImagePreviewModal] = useState(null);
    const [fileList, setFileList] = useState([])
    const [submitLoading, setSubmitLoading] = useState(false);
    const [tempCaseForm, setTempCaseForm] = useState(defaultCaseValue);
    const [caseForm, setCaseForm] = useState(defaultCaseValue);
    const [apptValue, setApptValue] = useState('');

    const getPatientAppointments = async (patientId) => {
        if (patientId) {
            let res = await getAppointments({ patientId: patientId });
            if (res.status === 200 && res.data) {
                let existingData = res.data.find((item) => item.appointmentId === caseRecord.appointmentId);
                if (existingData) {
                    setApptValue(`${moment(existingData.start).tz(existingData.location.timezone).format('MM/DD/YYYY | hh:mma (z)')} - ${existingData.service.serviceName}[${existingData.service.type}]`)
                }
            }
        }
    }

    useEffect(() => {
        let existingData = {
            type: caseRecord?.type ? caseRecord.type : "",
            priority: caseRecord?.priority ? caseRecord.priority : "",
            message: caseRecord?.comments ? caseRecord.comments : "",
            note: caseRecord?.noteFromStaff ? caseRecord.noteFromStaff : "",
            resolveCase: caseRecord?.resolveCase ? caseRecord.resolveCase : "",
            status: caseRecord?.status ? caseRecord.status : "new",
            cc: caseRecord?.cc ? caseRecord.cc : [],
            files: caseRecord?.files ? caseRecord.files : [],
            appointmentId: caseRecord?.appointmentId ? caseRecord.appointmentId : ""
        }

        let symptomsAndGoalsTracker = defaultCaseValue.symptomsAndGoalsTracker
        if (currentPatient?.patientId === caseRecord?.patientId) {
            symptomsAndGoalsTracker = currentPatient?.symptomsAndGoalsTracker;
        } else if (caseRecord?.symptomsAndGoalsTracker) {
            symptomsAndGoalsTracker = caseRecord?.symptomsAndGoalsTracker;
        }

        existingData["symptomsAndGoalsTracker"] = {
            sleep: symptomsAndGoalsTracker?.sleep || "None",
            weight: symptomsAndGoalsTracker?.weight || "None",
            energy: symptomsAndGoalsTracker?.energy || "None",
            sexual: symptomsAndGoalsTracker?.sexual || "None",
            cognitive: symptomsAndGoalsTracker?.cognitive || "None",
            healing: symptomsAndGoalsTracker?.healing || "None"
        };

        if (caseRecord?.files && caseRecord?.files.length) {
            let newFile = [];
            newFile = caseRecord.files.map((item, index) => (
                {
                    uid: index,
                    name: item.name,
                    status: 'done',
                    url: item.url,
                    type: item.fileType
                }
            ))
            setFileList([...newFile]);
        } else {
            setFileList([])
        }
        if (caseRecord?.patientId) {
            (async () => {
                await getPatientAppointments(caseRecord?.patientId);
            })();
        }
        setTempCaseForm({
            ...tempCaseForm,
            ...existingData
        })
        setCaseForm({
            ...caseForm,
            ...existingData
        })
    }, [caseRecord, modalOpen]);

    const refreshFormValue = async () => {
        setCaseForm(tempCaseForm);
    }

    const modalError = (title, content) => {
        Modal.error({
            title: title,
            content: (
                <div>{content}</div>
            )
        });
    }

    const modalSuccess = (title, content) => {
        Modal.success({
            title: title,
            content: (
                <div>{content}</div>
            )
        })
    }

    const fieldErrors = () => {
        const errors = [];
        if (!caseForm.type.length) {
            errors.push('Case type not allow to be empty');
        }
        if (!caseForm.priority.length) {
            errors.push('Priority not allow to be empty');
        }
        if (!caseForm.message.length) {
            errors.push('Message not allow to be empty');
        }
        if (!caseForm.status.length) {
            errors.push('Status not allow to be empty');
        }
        return errors;
    };

    const handleReopenCase = async () => {
        let title, okText, cancelText, onOk, onCancel;
        title = 'Reopen Case';
        okText = 'Confirm';
        cancelText = 'Cancel';
        onOk = () => {
            handleUpdate('reopen');
        };
        Modal.confirm({
            title: (
                <p>
                    <div>{title}</div>
                    <Typography.Text type={'secondary'}>Are you sure to reopen case?</Typography.Text>
                </p>
            ),
            okText,
            onOk,
            maskClosable: true,
            footer: (_, { OkBtn }) => (
                <>
                    <Button
                        onClick={() => {
                            (onCancel ? onCancel : () => { })();
                            Modal.destroyAll();
                        }}
                    >
                        {cancelText}
                    </Button>
                    <OkBtn />
                </>
            ),
        });
    }

    const handleUpdate = async (action) => {
        let finalCaseForm = caseForm;
        if (action && action === 'reopen') {
            finalCaseForm = { ...finalCaseForm, status: 'open' };
        }
        setSubmitLoading(true);
        try {
            await updateCase(caseRecord._id, finalCaseForm).then((res) => {
                setSubmitLoading(false);
                if (res.data) {
                    if (res.data.status === 500) {
                        modalError("Failed", res.data.message);
                    }
                    if (res.data.status === 200) {
                        modalSuccess("Success", res.data.message);
                        setModalOpen(false);
                        refreshFormValue();
                        fetchPetientRequest();
                    }
                }
            }).catch((err) => {
                modalError(err.message, err.response.data.message);
                setSubmitLoading(false);
            })
        } catch (err) {
            modalError(err.message, err.response.data.message);
            setSubmitLoading(false);
        }
    }

    return (
        <Modal
            open={modalOpen}
            centered
            width={'95%'}
            footer={null}
            destroyOnClose={true}
            onCancel={() => setModalOpen(false)}
            maskClosable={false}
        >
            <div className='d-flex flex-column gap-4 case-form'>
                <h5>
                    View case
                </h5>
                <div className='row g-5'>
                    <div className='col-xl-6 col-12 d-flex flex-column gap-3'>
                        <div className='row'>
                            <div className='col-md-3 col-12'>
                                <label>Case ID:</label>
                            </div>
                            <div className='col-md-9 col-12'>
                                <p>{caseRecord && caseRecord.caseId ? caseRecord.caseId : ""}</p>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-3 col-12'>
                                <label>Date created:</label>
                            </div>
                            <div className='col-md-9 col-12'>
                                <p>{caseRecord && caseRecord.creationTS ? moment(caseRecord.creationTS).format('MMMM Do, YYYY hh:mm A') : ""}</p>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-3 col-12'>
                                <label>Created by:</label>
                            </div>
                            <div className='col-md-9 col-12'>
                                <p>{caseRecord && caseRecord.createdBy && caseRecord.createdBy.name ? caseRecord.createdBy.name : ""}</p>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-3 col-12'>
                                <label>Created by:</label>
                            </div>
                            <div className='col-md-9 col-12'>
                                <p>{caseRecord && caseRecord.patientInfo && caseRecord.patientInfo.name ? caseRecord.patientInfo.name : ""}</p>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-3 col-12'>
                                <label>Assign to:</label>
                            </div>
                            <div className='col-md-9 col-12'>
                                <div className='d-flex flex-column gap-3'>
                                    {
                                        caseRecord?.assigneesDetails && (
                                            <List
                                                size="small"
                                                bordered
                                                dataSource={caseRecord.assigneesDetails}
                                                renderItem={(item) => (
                                                    <List.Item>
                                                        <List.Item.Meta
                                                            avatar={item.type === "individual" ? (item.coverImage ? (
                                                                <Avatar src={item.coverImage} />
                                                            ) : (
                                                                <Avatar icon={<UserOutlined />} />
                                                            )) : item.type === "group" ? <GroupsIcon /> : null}
                                                            title={item.type === "group" ? item.groupName : item.type === "individual" ? item.fullName : ""}
                                                            description={item.type === "group" ? item.groupId : item.type === "individual" ? item.staffId : ""}
                                                        />
                                                        {item.type === "group" && (
                                                            <Tag color="default">Group</Tag>
                                                        )}
                                                    </List.Item>
                                                )}
                                            />
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-3 col-12'>
                                <label>
                                    CC:
                                </label>
                            </div>
                            <div className='col-md-9 col-12'>
                                {
                                    caseRecord?.ccDetails && (
                                        <List
                                            size="small"
                                            bordered
                                            dataSource={caseRecord?.ccDetails}
                                            renderItem={(item) => (
                                                <List.Item>
                                                    <List.Item.Meta
                                                        avatar={item.type === "individual" ? (item.coverImage ? (
                                                            <Avatar src={item.coverImage} />
                                                        ) : (
                                                            <Avatar icon={<UserOutlined />} />
                                                        )) : item.type === "group" ? <GroupsIcon /> : null}
                                                        title={item.type === "group" ? item.groupName : item.type === "individual" ? item.fullName : ""}
                                                        description={item.type === "group" ? item.groupId : item.type === "individual" ? item.staffId : ""}
                                                    />
                                                    {item.type === "group" && (
                                                        <Tag color="default">Group</Tag>
                                                    )}
                                                </List.Item>
                                            )}
                                        />
                                    )
                                }
                            </div>
                        </div>
                        <div className='row align-items-center'>
                            <div className='col-md-3 col-12'>
                                <label>
                                    Case type:
                                </label>
                            </div>
                            <div className='col-md-9 col-12'>
                                {caseTypes && caseForm.type ? caseTypes.find((item) => item.value === caseForm.type)?.label : "N/A"}
                            </div>
                        </div>
                        {
                            caseTypes && caseForm.type === "appointment-adjustment" ? (
                                <div className='row align-items-center'>
                                    <div className='col-md-3 col-12'>
                                        <label>
                                            Appointment Id:
                                        </label>
                                    </div>
                                    <div className='col-md-9 col-12'>
                                        {apptValue.length ? apptValue : "N/A"}
                                    </div>
                                </div>
                            ) : null
                        }
                        <div className='row align-items-center'>
                            <div className='col-md-3 col-12'>
                                <label>
                                    Priority:
                                </label>
                            </div>
                            <div className='col-md-9 col-12'>
                                {caseForm.priority ? casePriorityData.find((item) => item.value === caseForm.priority)?.text : "N/A"}
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-3 col-12'>
                                <label>
                                    Message:
                                </label>
                            </div>
                            <div className='col-md-9 col-12'>
                                {caseForm.message ? caseForm.message : "N/A"}
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-3 col-12'>
                                <label>
                                    Add comment:
                                </label>
                            </div>
                            <div className='col-md-9 col-12'>
                                {caseForm.note ? caseForm.note : "N/A"}
                            </div>
                        </div>
                        <div className='row align-items-center'>
                            <div className='col-md-3 col-12'>
                                <label>
                                    Action taken to resolve case:
                                </label>
                            </div>
                            <div className='col-md-9 col-12'>
                                {caseForm.resolveCase ? caseResolveCaseData.find((item) => item.value === caseForm.resolveCase)?.text : "N/A"}
                            </div>
                        </div>
                        <div className='row align-items-center'>
                            <div className='col-md-3 col-12'>
                                <label>
                                    Status:
                                </label>
                            </div>
                            <div className='col-md-9 col-12'>
                                {caseForm.status ? caseStatusData.find((item) => item.value === caseForm.status.toLowerCase())?.text : "N/A"}
                            </div>
                        </div>
                    </div>
                    <div className='col-xl-5 col-12 d-flex flex-column gap-5'>
                        {
                            caseRecord?.modifiedTS && caseRecord.modifiedBy ? (
                                <div className='row'>
                                    <div className='col-md-3 col-12'>
                                        <label>
                                            Last modified:
                                        </label>
                                    </div>
                                    <div className='col-md-9 col-12'>

                                        <p>{caseRecord.modifiedBy.name} - {moment(caseRecord.modifiedTS).format('MMMM Do, YYYY hh:mm A')}</p>

                                    </div>
                                </div>
                            ) : ""
                        }
                        <div className='row'>
                            <div className='col-3'>
                                <label>
                                    Upload file/images:
                                </label>
                            </div>
                            <div className='col-9'>
                                {
                                    fileList.length > 0 ? (
                                        <Upload
                                            maxCount={5}
                                            fileList={fileList}
                                        >
                                        </Upload>
                                    ) : "N/A"
                                }
                            </div>
                        </div>
                        <div style={{ maxWidth: '450px' }}>
                            <SymptonGoalTracker
                                viewOnly={true}
                                mergedForm={true}
                                data={caseForm?.symptomsAndGoalsTracker}
                            />
                        </div>
                    </div>
                </div>
                {
                    caseRecord?.status === "complete" && (
                        <div className='d-flex flex-row justify-content-center gap-3'>
                            <Tooltip title={fieldErrors().join(', ')} color={'#ef5350'}
                                placement={'right'}>
                                <Button type="primary" size={'large'} onClick={() => handleReopenCase()} loading={submitLoading}>
                                    Reopen Case
                                </Button>
                            </Tooltip>

                            <Button type="default" size="large" onClick={() => setModalOpen(false)}>Close</Button>
                        </div>
                    )
                }
            </div>
            <Modal
                open={!!imagePreviewModal}
                title={imagePreviewModal?.title}
                footer={null}
                onCancel={() => setImagePreviewModal(null)}
            >
                <img alt={imagePreviewModal?.title} style={{ width: '100%' }} src={imagePreviewModal?.src} />
            </Modal>
        </Modal>
    )
}

export default ViewCaseModal
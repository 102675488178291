import bioApi from '../../api/bioApi';
import { CASE_TYPE_URL, PATIENT_REQUESTS_URL, PATIENTS_SYMPTOMS_AND_GOALS_TRACKER_URL, PATIENTS_URL } from '../../api/URLs';

export const getPatientRequests = async (keyword, startDate, endDate) => {
  return await bioApi.post(PATIENT_REQUESTS_URL, { keyword, startDate, endDate });
};

export const changePatientRequestStatus = async (id, newStatus, note) => {
  return await bioApi.post(PATIENT_REQUESTS_URL + `/${id}`, { newStatus, note });
};

export const createCase = async (data) => {
  return await bioApi.post(PATIENT_REQUESTS_URL + `/create`, data)
}

export const updateCase = async (id, data) => {
  return await bioApi.post(PATIENT_REQUESTS_URL + `/edit/${id}`, data)
}

export const getCaseTypes = async () => {
  return await bioApi.get(CASE_TYPE_URL + '/list');
}

export const updateSymptonsAndGoalsTracker = async (data) => {
  return await bioApi.post(PATIENTS_SYMPTOMS_AND_GOALS_TRACKER_URL, data)
}
export const casePriorityData = [
    {
        value: "normal",
        text: "Normal"
    },
    {
        value: "high",
        text: "High"
    },
    {
        value: "urgent",
        text: "Urgent"
    }
]

export const caseResolveCaseData = [
    {
        value: "opened",
        text: "Opened"
    },
    {
        value: "assigned",
        text: "Assigned"
    },
    {
        value: "in-progress",
        text: "In Progress"
    },
    {
        value: "pending-approval",
        text: "Pending Approval"
    },
    {
        value: "completed",
        text: "Completed"
    },
]

export const caseStatusData = [
    {
        value: "new",
        text: "New"
    },
    {
        value: "open",
        text: "Open"
    },
    {
        value: "complete",
        text: "Complete"
    }
]

export const casePriorityOptions = () => {
    let newData = [];
    newData = casePriorityData.map((item) => ({
        label: item.text,
        value: item.value
    }));
    return newData;
}

export const caseResolveCaseOptions = () => {
    let newData = [];
    newData = caseResolveCaseData.map((item) => ({
        label: item.text,
        value: item.value
    }))
    return newData;
}

export const caseStatusOptions = () => {
    let newData = [];
    newData = caseStatusData.map((item) => ({
        label: item.text,
        value: item.value
    }))
    return newData;
}
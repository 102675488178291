import React, { useEffect } from 'react';
import { getSymptomsAndGoals, updateSymptomsAndGoals } from '../service';
import { useDispatch, useSelector } from 'react-redux';
import { updateCurrentPatient } from '../../../stores/current-patient.store';
import { Card } from 'react-bootstrap';
import { Checkbox, Col, Row, Spin } from 'antd';
import PatientRequestList from '../../patient-request/components/patient-request.component';
import AllTreatmentsPlans from '../../../components/all-treatment-plans.component';
import VisitDisplay from '../../patient-request/components/visit-display.component';
import SymptonGoalTracker from '../../patient-request/components/sympton-goal-tracker.component';

export const SymptomsAndGoalsPage = () => {
  const currentPatient = useSelector((state) => state.currentPatient);
  const dispatch = useDispatch();

  const [editMode, setEditMode] = React.useState(false);
  const [symptomsAndGoals, setSymptomsAndGoals] = React.useState([]);
  const [availableGoals, setAvailableGoals] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const toggleOrSave = async () => {
    if (editMode) {
      await updateSymptomsAndGoals(currentPatient.patientId, symptomsAndGoals);
      dispatch(
        updateCurrentPatient({
          key: 'symptomsAndGoals',
          value: symptomsAndGoals,
        })
      );
    }
    setEditMode(!editMode);
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      const goals = await getSymptomsAndGoals(currentPatient.patientId);
      if (goals.data) {
        const data = goals.data.data.map((sag) => ({
          label: sag.name,
        }));
        setAvailableGoals(data);
      }
      setLoading(false);
    })();
  }, []);

  React.useEffect(() => {
    if (currentPatient) {
      setSymptomsAndGoals(currentPatient.symptomsAndGoals);
    }
  }, [currentPatient]);

  return (
    <div className='d-flex flex-column gap-4' style={{ marginTop: 16 }}>
      <VisitDisplay />
      <PatientRequestList all={false} listSize={4} patientId={currentPatient.patientId} />
      <Row gutter={[12]}>
        <Col span={17}>
          <Card style={{ borderRadius: "6px", width: '100%', height: '100%' }}>
            <Card.Body>
              <div className="d-flex justify-content-between ps-3 pe-3 mb-2">
                <Card.Title>Symptoms / Goals</Card.Title>
                <Card.Link onClick={toggleOrSave}>{editMode ? 'Save' : 'Edit'}</Card.Link>
              </div>
              <div className={'d-flex justify-content-center'}>
                {loading ? (
                  <Spin size="large" />
                ) : (
                  <ul style={{ columns: 2, listStyle: 'none', flexGrow: 1 }}>
                    {availableGoals &&
                      availableGoals.map((ug) => (
                        <li>
                          <Checkbox
                            checked={symptomsAndGoals?.includes(ug.label)}
                            disabled={!editMode}
                            key={ug.label}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setSymptomsAndGoals([...symptomsAndGoals, ug.label]);
                              } else {
                                setSymptomsAndGoals([...symptomsAndGoals.filter((s) => s !== ug.label)]);
                              }
                            }}
                          >
                            {ug.label}
                          </Checkbox>
                        </li>
                      ))}
                  </ul>
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col span={7}>
          <SymptonGoalTracker
            data={currentPatient?.symptomsAndGoalsTracker}
          />
        </Col>
      </Row>
      <AllTreatmentsPlans displayCurrent={false} />
    </div>
  );
};

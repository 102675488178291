import { Avatar, Button, message, Modal, Select, Tag, Tooltip, Typography, Upload } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { searchPatients } from '../../patient/patient-search.service';
import TextArea from 'antd/es/input/TextArea';
import { fileToBase64 } from '../../../share/StringHelper';
import bioApi from '../../../api/bioApi';
import { UPLOAD_CONTENT_URL } from '../../../api/URLs';
import { searchGroupStaff } from '../../staff/staff-search.service';
import { createCase } from '../service';
import { useDispatch, useSelector } from 'react-redux';
import { casePriorityOptions, caseResolveCaseOptions, caseStatusOptions } from '../../../share/CaseSystem';
import moment from 'moment';
import GroupsIcon from '@mui/icons-material/Groups';
import SymptonGoalTracker from './sympton-goal-tracker.component';
import { updateCurrentPatient } from '../../../stores/current-patient.store';
import { UploadOutlined } from '@ant-design/icons';
import { checkIsOverlapedDate, getAppointments } from '../../patient/service';

const CreateCaseModal = ({ modalOpen, setModalOpen, fetchPetientRequest, all, caseTypes }) => {
    let currentPatient = useSelector((state) => state.currentPatient);
    const user = useSelector((state) => state.authenticatedUser);
    if (all) {
        currentPatient = null;
    }
    const defaultCaseValue = {
        patientId: currentPatient?.patientId ? currentPatient.patientId : "",
        type: "",
        priority: "",
        message: "",
        resolveCase: "",
        status: "new",
        assignee: [],
        files: [],
        appointmentId: "",
        symptomsAndGoalsTracker: {
            sleep: currentPatient?.symptomsAndGoalsTracker?.sleep || "None",
            weight: currentPatient?.symptomsAndGoalsTracker?.weight || "None",
            energy: currentPatient?.symptomsAndGoalsTracker?.energy || "None",
            sexual: currentPatient?.symptomsAndGoalsTracker?.sexual || "None",
            cognitive: currentPatient?.symptomsAndGoalsTracker?.cognitive || "None",
            healing: currentPatient?.symptomsAndGoalsTracker?.healing || "None"
        }
    }
    const dispatch = useDispatch();
    const symptonTrackerRef = useRef(null);
    const [errorField, setErrorField] = useState([]);
    const [imagePreviewModal, setImagePreviewModal] = useState(null);
    const [fileList, setFileList] = useState([])
    const [submitLoading, setSubmitLoading] = useState(false);
    const [patientSearchResult, setPatientSearchResult] = useState([]);
    const [staffSearchResult, setStaffSearchResult] = useState([]);
    const [caseForm, setCaseForm] = useState(defaultCaseValue);
    const [isChanged, setIsChanged] = useState(false);
    const [appointmentData, setAppointmentData] = useState([]);
    const today = moment().format("MMMM Do, YYYY");

    let patientSearchDebounceTimeout;
    let currentCriteria;

    useEffect(() => {
        setCaseForm({ ...defaultCaseValue });
    }, [currentPatient]);

    const getPatientAppointments = async (patientId) => {
        if (patientId) {
            let res = await getAppointments({ patientId: patientId });
            if (res.status === 200 && res.data) {
                let newApptData = res.data.filter((item) => ["Booked"].includes(item.status) && checkIsOverlapedDate(item.start));
                setAppointmentData(newApptData)
            }
        } else {
            setAppointmentData([])
        }
    }

    const handlePatientSearch = (newValue) => {
        if (newValue && newValue.length > 3) {
            if (patientSearchDebounceTimeout) {
                clearTimeout(patientSearchDebounceTimeout);
                patientSearchDebounceTimeout = null;
            }
            currentCriteria = newValue;

            patientSearchDebounceTimeout = setTimeout(async () => {
                const result = await searchPatients('general-matching', newValue);
                if (currentCriteria === newValue) {
                    setPatientSearchResult(result.data.patients);
                }
            }, 300);
        } else {
            setPatientSearchResult([]);
        }
    };

    let staffSearchDebounceTimeout;
    let currentStaff;
    const handleAssigneeSearch = (newValue) => {
        if (newValue && newValue.length > 2) {
            if (staffSearchDebounceTimeout) {
                clearTimeout(staffSearchDebounceTimeout);
                staffSearchDebounceTimeout = null;
            }
            currentStaff = newValue;

            staffSearchDebounceTimeout = setTimeout(async () => {
                const result = await searchGroupStaff('general-matching', newValue);
                if (currentStaff === newValue) {
                    setStaffSearchResult(result.data);
                }
            }, 300);
        } else {
            setStaffSearchResult([]);
        }
    }

    const fieldErrors = () => {
        let errors = [];
        if (!caseForm.assignee.length) {
            errors.push('Assign to not allow to be empty');
        }
        if (!caseForm.patientId.length) {
            errors.push('Patient Name not allow to be empty');
        }
        if (!caseForm.type.length) {
            errors.push('Case type not allow to be empty');
        }
        if (!caseForm.priority.length) {
            errors.push('Priority not allow to be empty');
        }
        if (!caseForm.message.length) {
            errors.push('Message not allow to be empty');
        }
        if (!caseForm.status.length) {
            errors.push('Status not allow to be empty');
        }
        if (caseForm.type === "appointment-adjustment" && !caseForm.appointmentId) {
            errors.push('Please select an appointment');
        }

        let symptonFormError = symptonTrackerRef?.current?.validateSymptonForm();
        if (symptonFormError?.length > 0) {
            errors = errors.concat(symptonFormError);
        }

        setErrorField([...errors]);
    };

    const handleSelectAssignee = async (value) => {
        setCaseForm({ ...caseForm, assignee: value ? value : "" })
    }

    const handleSelectPatient = async (value) => {
        const selectedPatient = patientSearchResult.find(searchResult => searchResult.patientId === value);
        setCaseForm({
            ...caseForm,
            patientId: value ? value : "",
            symptomsAndGoalsTracker: {
                sleep: selectedPatient?.symptomsAndGoalsTracker?.sleep || "None",
                weight: selectedPatient?.symptomsAndGoalsTracker?.weight || "None",
                energy: selectedPatient?.symptomsAndGoalsTracker?.energy || "None",
                sexual: selectedPatient?.symptomsAndGoalsTracker?.sexual || "None",
                cognitive: selectedPatient?.symptomsAndGoalsTracker?.cognitive || "None",
                healing: selectedPatient?.symptomsAndGoalsTracker?.healing || "None",
            }
        })
    }

    const handleSelectCaseType = async (value) => {
        let data = {
            type: value ? value : ""
        }
        if (value === 'medical-reaction') {
            data['priority'] = 'urgent'
        }
        setCaseForm({ ...caseForm, ...data })
    }

    const handleSelectPriority = async (value) => {
        setCaseForm({ ...caseForm, priority: value ? value : "" })
    }

    const handleSelectResolveCase = async (value) => {
        setCaseForm({ ...caseForm, resolveCase: value ? value : "" })
    }

    const handleSelectStatus = async (value) => {
        setCaseForm({ ...caseForm, status: value ? value : "" })
    }

    const refreshFormValue = async () => {
        setCaseForm(defaultCaseValue);
        setFileList([]);
    }

    const modalError = (title, content) => {
        Modal.error({
            title: title,
            content: (
                <div>{content}</div>
            )
        });
    }

    const modalSuccess = (title, content) => {
        Modal.success({
            title: title,
            content: (
                <div>{content}</div>
            )
        })
    }

    const handleSubmit = async () => {
        setSubmitLoading(true);
        try {
            await createCase(caseForm).then((res) => {
                setSubmitLoading(false);
                if (res.data) {
                    if (res.data.status === 500) {
                        modalError("Failed", "Something went wrong. Please try again later.");
                    }
                    if (res.data.status === 200) {
                        modalSuccess("Success", res.data.message);
                        setModalOpen(false);
                        refreshFormValue();
                        fetchPetientRequest();
                        if (currentPatient != null) {
                            dispatch(
                                updateCurrentPatient({
                                    key: 'symptomsAndGoalsTracker',
                                    value: caseForm.symptomsAndGoalsTracker,
                                })
                            );
                        }
                    }
                }
            }).catch((err) => {
                modalError(err.message, err.response.data.message);
                setSubmitLoading(false);
            })
        } catch (err) {
            modalError(err.message, err.response.data.message);
            setSubmitLoading(false);
        }
    }

    useEffect(() => {
        let newFileList = [];
        if (fileList.length) {
            newFileList = fileList.map((item) => ({
                name: item.name,
                url: item.url,
                fileType: item.type
            }))
        }
        setCaseForm({ ...caseForm, files: newFileList })
    }, [fileList])

    useEffect(() => {
        const hasChanges = Object.keys(defaultCaseValue).some(
            (key) =>
                JSON.stringify(caseForm[key]) !== JSON.stringify(defaultCaseValue[key])
        );
        setIsChanged(hasChanges);
        fieldErrors();

        (async () => {
            await getPatientAppointments(caseForm.patientId);
        })();
    }, [caseForm]);

    const onModalClose = async () => {
        let title, okText, cancelText, onOk, onCancel;
        if (isChanged) {
            title = 'Closing now will discard all changes you just made';
            okText = 'Close';
            cancelText = 'Stay';
            onOk = () => {
                refreshFormValue();
                setModalOpen(false);
            };
            Modal.confirm({
                title: (
                    <p>
                        <div>{title}</div>
                        <Typography.Text type={'secondary'}>Click outside to dismiss this dialog</Typography.Text>
                    </p>
                ),
                okText,
                onOk,
                maskClosable: true,
                footer: (_, { OkBtn }) => (
                    <>
                        <Button
                            onClick={() => {
                                (onCancel ? onCancel : () => { })();
                                Modal.destroyAll();
                            }}
                        >
                            {cancelText}
                        </Button>
                        <OkBtn />
                    </>
                ),
            });
        } else {
            setModalOpen(false)
        }
    }

    const uploadProps = {
        name: 'file',
        maxCount: 5,
        action: bioApi.defaults.baseURL + UPLOAD_CONTENT_URL,
        showUploadList: true,
        accept: ".png,.jpg,.jpeg,.pdf,.doc,.docx",
        fileList: fileList,
        onChange(info) {
            const { file, fileList: newFileList } = info;

            if (file.status === 'removed') {
                let newFile = [];
                newFile = fileList.filter((item) => item.uid !== file.uid)
                setFileList([...newFile]);
            }

            if (file.status === 'uploading') {
                setFileList(newFileList);
            }

            if (file.status === "done") {
                const responseData = file.response; // Response from server
                const updatedFileList = newFileList.map((item) => {
                    if (item.uid === file.uid) {
                        return {
                            ...item,
                            url: responseData.data,
                        };
                    }
                    return item;
                });

                setFileList(updatedFileList);
                message.success(`${file.name} uploaded successfully.`);
            } else if (file.status === "error") {
                message.error(`${file.name} upload failed.`);
            }
        },
        withCredentials: true,
    };

    return (
        <Modal
            open={modalOpen}
            centered
            width={'95%'}
            footer={null}
            destroyOnClose={true}
            onCancel={onModalClose}
            maskClosable={false}
        >
            <div className='d-flex flex-column gap-4 case-form'>
                <h5>
                    Create new case
                </h5>
                <div className='row g-5'>
                    <div className='col-xl-6 col-12 d-flex flex-column gap-3'>
                        <div className='row'>
                            <div className='col-md-3 col-12'>
                                <label>Date created:</label>
                            </div>
                            <div className='col-md-9 col-12'>
                                <p>{today ? today : ""}</p>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-3 col-12'>
                                <label>Created by:</label>
                            </div>
                            <div className='col-md-9 col-12'>
                                <p>{user ? user.name : ""}</p>
                            </div>
                        </div>
                        <div className='row align-items-center'>
                            <div className='col-md-3 col-12'>
                                <label><span style={{ color: "red" }}>*</span>Assign to:</label>
                            </div>
                            <div className='col-md-9 col-12'>
                                <Select
                                    mode="multiple"
                                    showSearch
                                    placeholder="Search within individual or group"
                                    size="large"
                                    style={{ minWidth: 420 }}
                                    defaultActiveFirstOption={false}
                                    filterOption={false}
                                    value={caseForm.assignee}
                                    onSearch={handleAssigneeSearch}
                                    onChange={handleSelectAssignee}
                                    optionLabelProp="label"
                                    allowClear
                                >
                                    {(staffSearchResult || []).map((s) => (
                                        s?.type === "individual" ? (
                                            <Select.Option value={s.staffId} label={s.fullName}>
                                                <div className='d-flex flex-row align-items-center justify-content-between'>
                                                    <div className="d-flex align-items-center">
                                                        <Avatar size="30" src={s.coverImage} style={{ marginRight: 10 }} />
                                                        <div>
                                                            <div>{s.fullName}</div>
                                                            <div>{s.staffId}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Select.Option>
                                        ) : s?.type === "group" ? (
                                            <Select.Option value={s.groupId} label={s.groupName}>
                                                <div className='d-flex flex-row align-items-center justify-content-between'>
                                                    <div className="d-flex align-items-center">
                                                        <GroupsIcon style={{ marginRight: 10 }} />
                                                        <div>
                                                            <div>{s.groupName}</div>
                                                            <div>{s.groupId}</div>
                                                        </div>
                                                    </div>
                                                    <Tag color="default">Group</Tag>
                                                </div>
                                            </Select.Option>
                                        ) : null
                                    ))}
                                </Select>
                            </div>
                        </div>
                        <div className='row align-items-center'>
                            <div className='col-md-3 col-12'>
                                <label><span style={{ color: "red" }}>*</span>Patient Name:</label>
                            </div>
                            <div className='col-md-9 col-12'>
                                {
                                    currentPatient && currentPatient.profile ? (
                                        <div>
                                            <p className='mb-0'>{currentPatient.profile.firstName} {currentPatient.profile.lastName}</p>
                                        </div>
                                    ) : (
                                        <Select
                                            showSearch
                                            placeholder="Search by Patient ID, Name, Phone Number and Email or App User ID"
                                            size="large"
                                            style={{ minWidth: 420 }}
                                            defaultActiveFirstOption={false}
                                            filterOption={false}
                                            value={caseForm.patientId}
                                            onSearch={handlePatientSearch}
                                            onChange={handleSelectPatient}
                                            optionLabelProp="label"
                                            allowClear
                                        >
                                            {(patientSearchResult || []).map((p) => (
                                                <Select.Option value={p.patientId} label={p.fullName}>
                                                    <div className="d-flex align-items-center">
                                                        <Avatar size="30" src={p.picture} style={{ marginRight: 10 }} />
                                                        <div>
                                                            <div>{p.fullName}</div>
                                                            <div>
                                                                (Phone #{(p.phoneNumber || '').replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')})
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    )
                                }
                            </div>
                        </div>
                        <div className='row align-items-center'>
                            <div className='col-md-3 col-12'>
                                <label><span style={{ color: "red" }}>*</span>Case type:</label>
                            </div>
                            <div className='col-md-9 col-12'>
                                <Select
                                    showSearch
                                    size="large"
                                    placeholder="Search to Select"
                                    style={{ minWidth: 420 }}
                                    value={caseForm.type}
                                    onChange={handleSelectCaseType}
                                    options={caseTypes}
                                    allowClear
                                />
                            </div>
                        </div>
                        {
                            caseForm.type && caseForm.type === "appointment-adjustment" ?
                                <div className='row align-items-center'>
                                    <div className='col-md-3 col-12'>
                                    </div>
                                    <div className='col-md-9 col-12'>
                                        <div className='d-flex flex-column'>
                                            <label><span style={{ color: "red" }}>*</span>Select Appointment:</label>
                                            <Select
                                                placeholder="Select Appointment"
                                                size="large"
                                                style={{ minWidth: 420, maxWidth: 420 }}
                                                defaultActiveFirstOption={false}
                                                filterOption={false}
                                                value={caseForm.appointmentId}
                                                onChange={(value) => {
                                                    setCaseForm({ ...caseForm, appointmentId: value ? value : "" })
                                                }}
                                                optionLabelProp="label"
                                                allowClear
                                            >
                                                {(appointmentData || []).map((item) => {
                                                    let label = `${moment(item.start).tz(item.location.timezone).format('MM/DD/YYYY | hh:mma (z)')} - ${item.service.serviceName}[${item.service.type}]`
                                                    return (
                                                        <Select.Option value={item.appointmentId} label={label}>
                                                            {label}
                                                        </Select.Option>
                                                    )
                                                })}
                                            </Select>
                                        </div>
                                    </div>
                                </div>
                                : null
                        }
                        <div className='row align-items-center'>
                            <div className='col-md-3 col-12'>
                                <label><span style={{ color: "red" }}>*</span>Priority:</label>
                            </div>
                            <div className='col-md-9 col-12'>
                                <Select
                                    showSearch
                                    size="large"
                                    placeholder="Search to Select"
                                    style={{ minWidth: 420 }}
                                    value={caseForm.priority}
                                    onChange={handleSelectPriority}
                                    options={casePriorityOptions()}
                                    allowClear
                                />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-3 col-12'>
                                <label><span style={{ color: "red" }}>*</span>Message:</label>
                            </div>
                            <div className='col-md-9 col-12'>
                                <TextArea value={caseForm.message} onChange={(e) => setCaseForm({ ...caseForm, message: e.target.value })} rows={4} />
                            </div>
                        </div>
                        <div className='row align-items-center'>
                            <div className='col-md-3 col-12'>
                                <label>
                                    Action taken to resolve case:
                                </label>
                            </div>
                            <div className='col-md-9 col-12'>
                                <Select
                                    showSearch
                                    size="large"
                                    placeholder="Search to Select"
                                    style={{ minWidth: 420 }}
                                    value={caseForm.resolveCase}
                                    onChange={handleSelectResolveCase}
                                    options={caseResolveCaseOptions()}
                                    allowClear
                                />
                            </div>
                        </div>
                        <div className='row align-items-center'>
                            <div className='col-md-3 col-12'>
                                <label><span style={{ color: "red" }}>*</span>Status:</label>
                            </div>
                            <div className='col-md-9 col-12'>
                                <Select
                                    showSearch
                                    size="large"
                                    placeholder="Search to Select"
                                    style={{ minWidth: 420 }}
                                    value={caseForm.status}
                                    onChange={handleSelectStatus}
                                    options={caseStatusOptions()}
                                    allowClear
                                />
                            </div>
                        </div>
                    </div>
                    <div className='col-xl-5 col-12 d-flex flex-column gap-5'>
                        <div className='row'>
                            <div className='col-3'>
                                <label>Upload file/images:</label>
                            </div>
                            <div className='col-9'>
                                <Upload
                                    {...uploadProps}
                                >
                                    {
                                        fileList.length >= 5 ? null : (
                                            <Button icon={<UploadOutlined />}>
                                                Upload
                                            </Button>
                                        )
                                    }
                                </Upload>
                            </div>
                        </div>
                        <div style={{ maxWidth: '450px' }}>
                            <SymptonGoalTracker
                                ref={symptonTrackerRef}
                                mergedForm={true}
                                viewOnly={!caseForm?.patientId}
                                onFieldsChange={(key, value) => {
                                    setCaseForm({ ...caseForm, symptomsAndGoalsTracker: { ...caseForm.symptomsAndGoalsTracker, [key]: value } });
                                    fieldErrors();
                                }}
                                data={caseForm?.symptomsAndGoalsTracker}
                            />
                        </div>
                    </div>
                </div>
                <div className='d-flex flex-row justify-content-center gap-3'>
                    <Tooltip title={errorField.join(', ')} color={'#ef5350'}
                        placement={'right'}>
                        <Button type="primary" size={'large'} disabled={!!errorField.length || !isChanged} onClick={handleSubmit} loading={submitLoading}>
                            Submit
                        </Button>
                    </Tooltip>
                    <Button type="default" size="large" onClick={() => onModalClose()}>Cancel</Button>
                </div>
            </div>
            <Modal
                open={!!imagePreviewModal}
                title={imagePreviewModal?.title}
                footer={null}
                onCancel={() => setImagePreviewModal(null)}
            >
                <img alt={imagePreviewModal?.title} style={{ width: '100%' }} src={imagePreviewModal?.src} />
            </Modal>
        </Modal>
    )
}

export default CreateCaseModal
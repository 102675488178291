import React, { useEffect, useState } from 'react'
import { Input } from 'antd'
import { Button, Col, Row } from 'react-bootstrap';
import DataTable from '../../../components/data-table/data-table.component'
import { Link } from 'react-router-dom';
import '../staff-search.css';
import { listAllStaffGroup } from '../staff-search.service';
import moment from 'moment';
import StaffGroupCreateModal from './staff-group-create-modal';
import StaffGroupViewEdit from './staff-group-view-edit-modal';

const StaffGroupPage = () => {
    const [loading, setLoading] = useState(false);
    const [staffGroupList, setStaffGroupList] = useState([]);
    const [searchResult, setSearchResult] = useState([]);
    const [createModal, setCreateModal] = useState(false);
    const [viewEditModal, setViewEditModal] = useState({
        show: false,
        record: {}
    });

    useEffect(() => {
        initLoadStaffGroup();
    }, []);

    useEffect(() => {
        filterTableResult('');
    }, [searchResult]);

    const filterTableResult = (value) => {
        const regex = new RegExp(value, 'i');
        const newStaffList = searchResult.filter((staff) => {
            return (
                regex.test(staff.groupName) ||
                regex.test(staff.groupId)
            );
        });
        setStaffGroupList(newStaffList);
    };

    const initLoadStaffGroup = () => {
        setLoading(true);
        listAllStaffGroup().then((response) => {
            if (response.data.status === 200) {
                setSearchResult(response.data?.data)
            }
            setLoading(false);
        });
    };

    const tableSettings = {
        appendable: false,
        removable: false,
        bordered: false,
        numbered: true,
        size: 'middle',
        pagination: {
            showLessItems: true,
            showSizeChanger: false,
            pageSize: 20,
        },
        columns: [
            {
                title: 'Group ID',
                dataIndex: ['groupId'],
                width: 200,
            },
            {
                title: 'Date Created',
                dataIndex: ['creationTS'],
                render: (text) => moment(text).format('MMMM DD, YYYY'),
                sorter: (a, b) => new Date(a.creationTS).getTime() - new Date(b.creationTS).getTime(),
                defaultSortOrder: 'descend',
                width: 200,
            },
            {
                title: 'Created By',
                dataIndex: ['createdBy'],
                render: (text) => {
                    return (
                        <div>{text.fullName}</div>
                    )
                },
                width: 200,
            },
        ],
        customActions: [
            {
                element: (record, data) => (
                    <div style={{ show: 'flex', alignItems: 'center' }}>
                        <Link
                            to="#"
                            onClick={(e) => {
                                e.preventDefault();
                                setViewEditModal({ show: true, record: record });
                            }}
                        >
                            {record.groupName}
                        </Link>
                    </div>
                ),
            },
        ],
        operationColumnTitle: 'Group Name',
        actionColumnIndex: 2,
        actions: {
            width: 200
        },
        numberedWidth: 50
    };

    return (
        <>
            <div style={{ background: '#F0F2F5 0% 0% no-repeat padding-box', padding: 32 }}>
                <div className="d-flex justify-content-between">
                    <h2>Staff Group Management</h2>
                    <Button onClick={() => setCreateModal(true)}>Create Staff Group</Button>
                </div>
                <br />
                <div className="d-flex justify-content-between">
                    <Input
                        placeholder="Search by Group's Name, Group ID, Phone Number, Email, Staff ID"
                        size="large"
                        allowClear
                        style={{ width: '50%' }}
                        onChange={(e) => filterTableResult(e.target.value)}
                    />
                </div>
                <Row className="section">
                    <Col sm={12}>
                        <div className="section-content">
                            <DataTable loading={loading} data={staffGroupList} settings={tableSettings} />
                        </div>
                    </Col>
                </Row>
            </div>
            <StaffGroupCreateModal modalOpen={createModal} setModalOpen={setCreateModal} initLoadStaffGroup={initLoadStaffGroup} />
            <StaffGroupViewEdit modalOpen={viewEditModal.show} handleCloseModal={() => setViewEditModal({ ...viewEditModal, show: false })} initLoadStaffGroup={initLoadStaffGroup} viewEditModal={viewEditModal} />
        </>
    )
}

export default StaffGroupPage
import React, {useEffect, useState} from 'react';
import '../../styles/nav.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import GroupsIcon from '@mui/icons-material/Groups';
import NotificationsIcon from '@mui/icons-material/Notifications';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import BadgeIcon from '@mui/icons-material/Badge';
import PunchClockIcon from '@mui/icons-material/PunchClock';
import { ConnectWithoutContact, SettingsSuggest } from '@mui/icons-material';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import CampaignIcon from '@mui/icons-material/Campaign';
import TagIcon from '@mui/icons-material/Tag';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import _ from 'lodash';
import { ApplicationRight } from '../../share/RightList';
import TerminalIcon from '@mui/icons-material/Terminal';
import { LuMicroscope } from 'react-icons/lu';
import { Badge } from 'antd';
import { useSelector } from 'react-redux';
import { RiTodoLine } from 'react-icons/ri';
import { FaUserDoctor } from 'react-icons/fa6';
import { GiMedicines } from 'react-icons/gi';
import {TREATMENT_FROM_ME_URL, TREATMENT_NEED_MY_REVIEW_URL} from "../../api/URLs";
import bioApi from "../../api/bioApi";

const NavigationSider = () => {
  const location = useLocation();
  const user = useSelector((state) => state.authenticatedUser);
  const patientConversations = useSelector((state) => state.patientConversations);

  const [treatmentDot, setTreatmentDot] = useState(false);

  useEffect(() => {
    try {
      (async () => {
        if (!!user) {
          let url;
          if (user.rightList.includes(ApplicationRight.Treatment_Review)) {
            url = TREATMENT_NEED_MY_REVIEW_URL;
          } else if (user.rightList.includes(ApplicationRight.Treatment_Creation)) {
            url = TREATMENT_FROM_ME_URL;
          }
          if (url) {
            const treatments = await bioApi.get(url);
            setTreatmentDot(!!treatments.data.length);
          }
        }
      })()
    } catch (e) {

    }
  }, []);

  const NavItem = ({ icon, title, pathname, accessingRights }) => {
    const hasRight =
      accessingRights && accessingRights.length ? !!_.intersection(user.rightList, accessingRights).length : true;
    return hasRight ? (
      <Link to={pathname}>
        <div
          className={`d-flex flex-row align-items-center nav-button ${
            pathname === location.pathname ? 'nav-active' : ''
          }`}
          style={{
            borderRadius: 4,
            marginBottom: 12,
            padding: 8,
            width: `180px`,
          }}
        >
          {icon}
          <span className="nav-title">{title}</span>
        </div>
      </Link>
    ) : (
      <></>
    );
  };

  const SiderNavSection = ({ title, navItems, isLast }) => {
    return (
      <div className="side-nav-section">
        <div style={{ padding: 12 }}>
          <span className="nav-mini">{title}</span>
          <div style={{ height: 6 }} />
          {navItems.map((item, i) => (
            <div key={i}>{item}</div>
          ))}
        </div>
        {!isLast && <div className="nav-seperate" />}
      </div>
    );
  };

  return (
    user && (
      <div className="nav-sider" style={{ position: 'relative' }}>
        <SiderNavSection
          title="Patient Management"
          navItems={[
            <NavItem
              pathname="/patient/search"
              icon={<GroupsIcon color="white" sx={{ color: 'white', width: `16px` }} />}
              title={'Patient Search'}
              accessingRights={[
                ApplicationRight.Patient_Detail_Access,
                ApplicationRight.Medical_Notes_Access,
                ApplicationRight.Lab_Info_Access,
                ApplicationRight.Treatment_Creation,
                ApplicationRight.BioID_Brief_Access,
                ApplicationRight.Appointment_Read_Access,
                ApplicationRight.Appointment_Creation,
                ApplicationRight.Appointment_Editing,
                ApplicationRight.Files_Access,
                ApplicationRight.PA_Group_Access,
              ]}
            />,
          ]}
        />
        <SiderNavSection
          title="Appointment Calendar"
          navItems={[
            <NavItem
              pathname="/appointment-management/by-location"
              icon={<MonitorHeartIcon color="white" sx={{ color: 'white', width: `16px` }} />}
              title={'By Location'}
              accessingRights={[ApplicationRight.Appointment_Read_Access]}
            />,
            <NavItem
              pathname="/appointment-management/by-provider"
              icon={<FaUserDoctor color="white" sx={{ color: 'white', width: `16px` }} />}
              title={'By Provider'}
              accessingRights={[ApplicationRight.Appointment_Read_Access]}
            />,
            <NavItem
              pathname="/staff/schedule"
              icon={<PunchClockIcon color="white" sx={{ color: 'white', width: `16px` }} />}
              title={'Staff Schedule'}
              accessingRights={[ApplicationRight.Staff_Schedule_Access]}
            />,
          ]}
        />

        <SiderNavSection
          title="Procurement"
          navItems={[
            <NavItem
              pathname="/procurement/shipment"
              icon={<RocketLaunchIcon color="white" sx={{ color: 'white', width: `16px` }} />}
              title={'Order'}
              accessingRights={[ApplicationRight.Orders_Tracking]}
            />,
          ]}
        />

        <SiderNavSection
          title="Treatments"
          navItems={[
            <NavItem
              pathname="/treatments"
              icon={<GiMedicines color="white" sx={{ color: 'white', width: `16px` }} />}
              title={
                <>
                  Treatment Plans <Badge dot={treatmentDot}></Badge>
                </>
              }
              accessingRights={[ApplicationRight.Treatment_Creation, ApplicationRight.Treatment_Review]}
            />,
          ]}
        />

        <SiderNavSection
          title="Opportunity"
          navItems={[
            <NavItem
              pathname="/opportunities-kanban"
              icon={<RiTodoLine color="white" sx={{ color: 'white', width: `16px` }} />}
              title={'Opportunities'}
              accessingRights={[
                ApplicationRight.Assigned_Opportunities_Access,
                ApplicationRight.All_Opportunities_Access,
              ]}
            />,
            <NavItem
              pathname="/cases"
              icon={<ConnectWithoutContact color="white" sx={{ color: 'white', width: `16px` }} />}
              title={<>Cases</>}
              accessingRights={[
                ApplicationRight.All_Patient_Request_Access,
                ApplicationRight.Assigned_Patient_Request_Access,
              ]}
            />,
          ]}
        />

        <SiderNavSection
          title="Engagement"
          navItems={[
            <NavItem
              pathname="/news-posts"
              icon={<CampaignIcon color="white" sx={{ color: 'white', width: `16px` }} />}
              title={'News Posts'}
              accessingRights={[ApplicationRight.News_Posts_Access]}
            />,
            <NavItem
              pathname="/announcement"
              icon={<AnnouncementIcon color="white" sx={{ color: 'white', width: `16px` }} />}
              title={'Announcement'}
              accessingRights={[ApplicationRight.Announcement_Access]}
            />,
            <NavItem
              pathname="/notification"
              icon={<NotificationsIcon color="white" sx={{ color: 'white', width: `16px` }} />}
              title={'Notifications'}
              accessingRights={[ApplicationRight.Notification_Access]}
            />,
            <NavItem
              pathname="/message-board"
              icon={<QuestionAnswerOutlinedIcon color="white" sx={{ color: 'white', width: `16px` }} />}
              title={
                <>
                  Messages <Badge dot={!!patientConversations.find((c) => c.hasUnreadMessage)}></Badge>
                </>
              }
              accessingRights={[
                ApplicationRight.All_Patient_Message_Access,
                ApplicationRight.Assigned_Patient_Message_Access,
              ]}
            />,
          ]}
        />

        <SiderNavSection
          title="System Content"
          navItems={[
            <NavItem
              pathname="/location"
              icon={<LocationOnIcon color="white" sx={{ color: 'white', width: `16px` }} />}
              title={'Location Management'}
              accessingRights={[ApplicationRight.Location_Management_Access]}
            />,
            <NavItem
              pathname="/staff/search"
              icon={<BadgeIcon color="white" sx={{ color: 'white', width: `16px` }} />}
              title={'Staff Management'}
              accessingRights={[ApplicationRight.Staff_Management_Access]}
            />,
            <NavItem
              pathname="/staff/group"
              icon={<GroupsIcon color="white" sx={{ color: 'white', width: `16px` }} />}
              title={'Staff Group'}
              accessingRights={[ApplicationRight.Staff_Management_Access]}
            />,
            <NavItem
              pathname="/system-settings"
              icon={<SettingsSuggest color="white" sx={{ color: 'white', width: `16px` }} />}
              title={'System Setting'}
              accessingRights={[ApplicationRight.System_Setting_Access]}
            />,
            <NavItem
              pathname="/app-version-config"
              icon={<TagIcon color="white" sx={{ color: 'white', width: `16px` }} />}
              title={'App Version Config'}
              accessingRights={[ApplicationRight.App_Version_Config_Access]}
            />,
            <NavItem
              pathname="/product-management"
              icon={<TerminalIcon color="white" sx={{ color: 'white', width: `16px` }} />}
              title={'Product Management'}
              accessingRights={[ApplicationRight.Product_Management_Access]}
            />,
            <NavItem
              pathname="/biomarker-management"
              icon={<LuMicroscope color="white" sx={{ color: 'white', width: `16px` }} />}
              title={'Biomarker Management'}
              accessingRights={[ApplicationRight.Biomarker_Management_Access]}
            />,
          ]}
          isLast
        />
      </div>
    )
  );
};

export default NavigationSider;

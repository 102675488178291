import { Form, Modal, Select, Spin, Tooltip } from 'antd';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Card } from 'react-bootstrap';
import { updateSymptonsAndGoalsTracker } from '../service';
import { useDispatch, useSelector } from 'react-redux';
import { updateCurrentPatient } from '../../../stores/current-patient.store';

function SymptonGoalTracker(props, ref) {
    const { viewOnly, mergedForm, onFieldsChange, data } = props;
    let currentPatient = useSelector((state) => state.currentPatient);

    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [editMode, setEditMode] = React.useState(mergedForm ?? false);
    const [loading, setLoading] = React.useState(false);
    const [errorField, setErrorField] = useState([]);
    const [initData, setInitData] = useState({});

    const initForm = (initData) => {
        initData = initData ?? data;
        initData = {
            sleep: initData?.sleep || "None",
            weight: initData?.weight || "None",
            energy: initData?.energy || "None",
            sexual: initData?.sexual || "None",
            cognitive: initData?.cognitive || "None",
            healing: initData?.healing || "None",
        };
        initData && Object.keys(initData).forEach(key => {
            form.setFieldValue(key, initData[key]);
        })
        setInitData({ ...initData });
    }

    useEffect(() => {
        if (mergedForm || !viewOnly) {
            initForm();
        } else {
            initForm(undefined);
        }
    }, [data])

    const handleValidateForm = () => {
        let errorField = symptons.filter(i => i.rules.length > 0 && (!form.getFieldValue(i.name) || form.getFieldValue(i.name) === "None"));

        if (errorField.length > 0) {
            return errorField.map(i => `${i.label} is required!`);
        }
        return [];
    };

    const handleChangeField = (key) => {
        if (mergedForm && !viewOnly) {
            onFieldsChange(key[0].name, !key[0].value ? "None" : key[0].value);
        } else {
            setErrorField(handleValidateForm());
        }
    }

    const handleCancelEditMode = () => {
        initForm({ ...initData });
        setEditMode(false)
        setErrorField([]);
    }

    const modalError = (title, content) => {
        Modal.error({
            title: title,
            content: (
                <div>{content}</div>
            )
        });
    }

    const modalSuccess = (title, content) => {
        Modal.success({
            title: title,
            content: (
                <div>{content}</div>
            )
        })
    }

    const toggleOrSave = async () => {
        if (editMode) {
            setLoading(true);
            try {
                const data = form.getFieldsValue();
                setErrorField([]);
                const res = await updateSymptonsAndGoalsTracker({
                    patientId: currentPatient.patientId,
                    symptomsAndGoalsTracker: data
                }).then(res => res.data);

                if (res.status === 200) {
                    modalSuccess("Success", res.message);
                    dispatch(
                        updateCurrentPatient({
                            key: 'symptomsAndGoalsTracker',
                            value: data,
                        })
                    );
                } else {
                    initForm();
                    modalError("Failed", "Something went wrong. Please try again later.");
                }
            } catch (error) {
                initForm();
                modalError("Failed", "Something went wrong. Please try again later.");
            } finally {
                setLoading(false);
            }
        } else {
            setErrorField(handleValidateForm());
        }
        setEditMode(!editMode);
    };

    const symptonGoalTrackerOptions = [
        { value: "None", label: "-None-" },
        { value: 'Needs Improvement', label: 'Needs Improvement' },
        { value: 'Improving', label: 'Improving' },
        { value: 'Optimal', label: 'Optimal' },
    ];

    const symptons = [
        { label: "Weight Loss & Body Comp Improvements", name: "weight", rules: [] },
        { label: "Sleeping Patterns", name: "sleep", rules: [] },
        { label: "Sexual Health", name: "sexual", rules: [] },
        { label: "Energy Levels", name: "energy", rules: [] },
        { label: "Cognitive Function", name: "cognitive", rules: [] },
        { label: "Healing/Recovery", name: "healing", rules: [] },
    ];

    useImperativeHandle(ref, () => {
        return {
            validateSymptonForm: handleValidateForm,
            initForm: initForm
        }
    })
    return (
        <Spin spinning={loading} size='large'>

            <Card style={{ borderRadius: '6px', width: '100%' }}>
                <Card.Body>
                    <div className="d-flex justify-content-between align-items-center ps-3 pe-3 mb-3">
                        <Card.Title className='mb-0'>Symptom/Goal Tracker</Card.Title>
                        {!mergedForm &&
                            <div>
                                <Tooltip title={errorField.join(', ')} color={'#ef5350'}
                                    placement={'right'}>
                                    <Card.Link
                                        onClick={() => {
                                            if (errorField.length === 0) {
                                                toggleOrSave();
                                            }
                                        }}
                                        className={errorField.length > 0 ? "link-disabled" : ""}
                                    >
                                        {editMode ? 'Save' : 'Edit'}
                                    </Card.Link>
                                </Tooltip>
                                {editMode && <Card.Link onClick={handleCancelEditMode}>
                                    Cancel
                                </Card.Link>}
                            </div>
                        }
                    </div>
                    <div className={'d-flex justify-content-center'}>
                        <Form
                            onFieldsChange={handleChangeField}
                            form={form}
                            className='w-100'
                            disabled={!editMode || viewOnly}
                            labelWrap
                            labelCol={{ span: 10 }}
                            wrapperCol={{ span: 14 }}
                        >
                            {symptons &&
                                symptons.map((ug) => (
                                    <Form.Item className='form-mb-none form-items-center' name={ug.name} label={<div style={{ fontWeight: 400 }}>{ug.label}</div>} rules={ug.rules}>
                                        <Select
                                            className='disabled-cursor-auto'
                                            size='small'
                                            key={ug.name}
                                            options={symptonGoalTrackerOptions}
                                        />
                                    </Form.Item>
                                ))}
                        </Form>
                    </div>
                </Card.Body>
            </Card>
        </Spin>
    )
}

export default forwardRef(SymptonGoalTracker);
import './App.css';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import NavigationSider from './components/navigations/NavigationSider.nav';
import NavigationTopper from './components/navigations/NavigationTopper.nav';

import LocationPage from './pages/Location.page';

import 'react-dropdown/style.css';
import AppVersionConfigPage from './pages/AppVersionConfig.page';

import AnnouncementPage from './pages/legacy/announcement/AnnouncementPage';
import NotificationPage from './pages/legacy/notification/NotificationPage';
import SystemSettingsContainerPage from './pages/system-settings/system-settings-container.page';
import GroupSettingsPage from './pages/system-settings/child-pages/group-settings/group-settings.page';
import LicenseAgreementPage from './pages/system-settings/child-pages/license-agreement/license-agreement.page';
import NewsPostsPage from './pages/news-posts/news-posts.page';
import PatientPage from './pages/patient/patient.page';
import PatientDetailsPage from './pages/patient/child-pages/details.page';
import MedicalNotesPage from './pages/patient/child-pages/medical-notes.page';
import LabInfoPage from './pages/patient/child-pages/lab-info.page';
import AppointmentPage from './pages/patient/child-pages/appointment.page';
import FilePage from './pages/patient/child-pages/files.page';
import TreatmentReviewPage from './pages/patient/child-pages/treatment-review.page';
import BioIdBriefPage from './pages/patient/child-pages/bio-id-brief.page';

import ProcurementOrderPage from './pages/procurement/order.page';
import ShipmentPage from './pages/procurement/child-pages/shipment.page';
import TrackingPage from './pages/procurement/child-pages/tracking.page';

import PatientSearchPage from './pages/patient/patient-search.page';
import StaffSearchPage from './pages/staff/staff-search.page';
import StaffCreatePage from './pages/staff/staff-create.page';
import NewAppointmentPage from './pages/patient/child-pages/new-appointment.page';
import StaffSchedulePage from './pages/staff/staff-schedule.page';
import AppointmentCalendarByLocationPage from './pages/appointments/appointment-calendar-by-location.page';
import AppointmentCalendarByProviderPage from './pages/appointments/appointment-calendar-by-provider.page';
import NewsPostsListPage from './pages/news-posts/child-pages/news-posts-list.page';
import NewsPostCreateUpdatePage from './pages/news-posts/child-pages/news-post-create-update.page';
import ProspectPatientPage from './pages/patient/prospect-patient.page';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoginPage from './pages/authen-page/LoginPage';
import bioApi from './api/bioApi';
import { AUTH_CHECK_URL } from './api/URLs';
import PageNotFound from './pages/not-found/PageNotFound';
import GuardedRoutes from './components/guarded-route/guarded-routes';
import RightsAndRolesPage from './pages/system-settings/child-pages/rights-and-roles/rights-and-roles.page';
import { ApplicationRight } from './share/RightList';
import ChatbotPresetPage from './pages/chatbotPreset/ChatbotPreset';
import ProductsPage from './pages/product-management/products.page';
import BiomarkerManagementPage from './pages/biomarker-management/biomarker-management.page';
import FeatureFlagPage from './pages/system-settings/child-pages/feature-flag/feature-flag.page';
import MessageBoardPage from './pages/message-board/message-board.page';
import PatientMessageEventSourceComponent from './pages/message-board/patient-message-event-source.component';
import PatientAdvocateGroupPage from './pages/patient/child-pages/pa-group.page';
import PatientRequestPage from './pages/patient-request/patient-request.page';
import PageNotAccess from './pages/not-access/PageNotAccess';
import LocationCreatePage from './pages/LocationCreate.page';
import LocationReviewPage from './pages/LocationReview.page';
import SendPasswordResetInstructionPage from './pages/authen-page/SendPasswordResetInstructionPage';
import PasswordResetPage from './pages/authen-page/PasswordResetPage';
import { SymptomsAndGoalsPage } from './pages/patient/child-pages/symptoms-and-goals.page';
import { OpportunitiesPage } from './pages/opportunities/opportunities.page';
import OrdersPage from './pages/patient/child-pages/orders.page';
import moment from 'moment';
import { setTimezone } from './stores/timezone.store';
import ConsentPage from './pages/patient/child-pages/consent.page';
import { TreatmentsNeedMyReviewPage } from './pages/treatments/treatments-need-my-review.page';
import { MyTreatmentSubmissionsPage } from './pages/treatments/my-treatment-submissions.page';
import StaffGroupPage from './pages/staff/staff-group/staff-group.page';

function App(props) {
  const user = useSelector((state) => state.authenticatedUser);
  const timezone = useSelector((state) => state.timezone);
  const [authenticated, setAuthenticated] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (timezone) {
    moment.tz.setDefault(timezone);
  }

  useEffect(() => {
    if (!user) {
      setAuthenticated(false);

      const allowPaths = ['/resetPassword', '/resetPasswordLink'];

      if (!allowPaths.includes(location.pathname)) {
        navigate('/login');
      }
    } else {
      if (!timezone) {
        dispatch(setTimezone(user.timezone));
      }
      (async () => {
        try {
          await bioApi.get(AUTH_CHECK_URL);
          setAuthenticated(true);
        } catch (e) {
          setAuthenticated(false);
        }
      })();
    }
  }, [user]);

  useEffect(() => {
    const allowPaths = ['/login', '/resetPassword', '/resetPasswordLink'];
    if (!allowPaths.includes(location.pathname)) {
      (async () => {
        await bioApi.get(AUTH_CHECK_URL);
      })();
    }
    const appContent = document.getElementById('app-content');
    if (appContent) {
      appContent.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [location]);

  return (
    <>
      <NavigationTopper />
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/resetPasswordLink" element={<SendPasswordResetInstructionPage />} />
        <Route path="/resetPassword" element={<PasswordResetPage />} />
      </Routes>
      <div className="App">
        {authenticated && (
          <>
            {user &&
            (user.rightList.includes(ApplicationRight.All_Patient_Message_Access) ||
              user.rightList.includes(ApplicationRight.Assigned_Patient_Message_Access)) ? (
              <PatientMessageEventSourceComponent />
            ) : (
              <></>
            )}
            <NavigationSider />
            <div
              style={{
                height: 'calc(100vh - 60px)',
                width: 'calc(100% - 180px)',
                overflow: 'auto',
              }}
              id="app-content"
            >
              <Routes>
                <Route path="/404" element={<PageNotFound />} />
                <Route path="/403" element={<PageNotAccess />} />

                <Route path="/" element={<Navigate to="/patient/search" />} />

                <Route element={<GuardedRoutes accessingRight={ApplicationRight.Location_Management_Access} />}>
                  <Route path="/location" element={<LocationPage />} />
                  <Route path="/location/create" element={<LocationCreatePage />} />
                  <Route path="/location/review" element={<LocationReviewPage {...props} />} />
                </Route>

                <Route element={<GuardedRoutes accessingRight={ApplicationRight.App_Version_Config_Access} />}>
                  <Route path="/app-version-config" element={<AppVersionConfigPage />} />
                </Route>

                <Route element={<GuardedRoutes accessingRight={ApplicationRight.Notification_Access} />}>
                  <Route path="/notification" element={<NotificationPage />} />
                </Route>

                <Route element={<GuardedRoutes accessingRight={ApplicationRight.Announcement_Access} />}>
                  <Route path="/announcement" element={<AnnouncementPage />} />
                </Route>

                <Route element={<GuardedRoutes accessingRight={ApplicationRight.System_Setting_Access} />}>
                  <Route path="/system-settings/" element={<SystemSettingsContainerPage />}>
                    <Route path="feature-flag" element={<FeatureFlagPage />} />
                    <Route path="group-settings" element={<GroupSettingsPage />} />
                    <Route path="license-agreement" element={<LicenseAgreementPage />} />
                    <Route path="rights-and-roles" element={<RightsAndRolesPage />} />
                    <Route path="content-chatbot" element={<ChatbotPresetPage />} />
                  </Route>
                </Route>

                <Route element={<GuardedRoutes accessingRight={ApplicationRight.News_Posts_Access} />}>
                  <Route path="/news-posts/" element={<NewsPostsPage />}>
                    <Route path="" element={<NewsPostsListPage />} />
                    <Route path="create-new" element={<NewsPostCreateUpdatePage compose />} />
                    <Route path="edit/:postId" element={<NewsPostCreateUpdatePage edit />} />
                    <Route path="templating-from/:postId" element={<NewsPostCreateUpdatePage template />} />
                  </Route>
                </Route>

                <Route path="/patient/:patientId" element={<PatientPage />}>
                  <Route element={<GuardedRoutes accessingRight={ApplicationRight.Patient_Detail_Access} />}>
                    <Route path="details" element={<PatientDetailsPage />} />
                  </Route>
                  <Route element={<GuardedRoutes accessingRight={ApplicationRight.Medical_Notes_Access} />}>
                    <Route path="medical-notes" element={<MedicalNotesPage />} />
                  </Route>
                  <Route element={<GuardedRoutes accessingRight={ApplicationRight.Lab_Info_Access} />}>
                    <Route path="lab-info" element={<LabInfoPage />} />
                  </Route>
                  <Route element={<GuardedRoutes accessingRight={ApplicationRight.Lab_Info_Access} />}>
                    <Route path="cases" element={<SymptomsAndGoalsPage />} />
                  </Route>
                  <Route element={<GuardedRoutes accessingRight={ApplicationRight.Treatment_Creation} />}>
                    <Route path="treatment" element={<TreatmentReviewPage />} />
                  </Route>
                  <Route element={<GuardedRoutes accessingRight={ApplicationRight.BioID_Brief_Access} />}>
                    <Route path="bioid-brief" element={<BioIdBriefPage />} />
                  </Route>
                  <Route element={<GuardedRoutes accessingRight={ApplicationRight.Orders_Tracking} />}>
                    <Route path="orders" element={<OrdersPage />} />
                  </Route>
                  {/*<Route element={<GuardedRoutes accessingRight={ApplicationRight.ess} />}>*/}
                  {/*  <Route path="engagement" element={<EngagementPage />} />*/}
                  {/*</Route>*/}
                  <Route element={<GuardedRoutes accessingRight={ApplicationRight.Appointment_Read_Access} />}>
                    <Route path="appointment" element={<AppointmentPage />} />
                  </Route>
                  {/*<Route element={<GuardedRoutes accessingRight={ApplicationRight.Payment_History_Access} />}>*/}
                  {/*  <Route path="payment" element={<PaymentPage />} />*/}
                  {/*</Route>*/}
                  <Route element={<GuardedRoutes accessingRight={ApplicationRight.Files_Access} />}>
                    <Route path="files" element={<FilePage />} />
                  </Route>
                  <Route element={<GuardedRoutes accessingRight={ApplicationRight.Appointment_Creation} />}>
                    <Route path="new-appointment" element={<NewAppointmentPage />} />
                  </Route>
                  <Route element={<GuardedRoutes accessingRight={ApplicationRight.PA_Group_Access} />}>
                    <Route path="pa-group" element={<PatientAdvocateGroupPage />} />
                  </Route>
                  <Route element={<GuardedRoutes accessingRight={ApplicationRight.Patient_Detail_Access} />}>
                    <Route path="consent" element={<ConsentPage />} />
                  </Route>
                </Route>

                <Route element={<GuardedRoutes accessingRight={ApplicationRight.Patient_Detail_Access} />}>
                  <Route path="/prospect-patient/:prospectPatientId" element={<ProspectPatientPage />}>
                    <Route path="details" element={<PatientDetailsPage />} />
                  </Route>
                </Route>

                <Route path="patient/search" element={<PatientSearchPage />} />

                <Route
                  element={
                    <GuardedRoutes
                      or
                      accessingRight={[
                        ApplicationRight.All_Patient_Message_Access,
                        ApplicationRight.Assigned_Patient_Message_Access,
                      ]}
                    />
                  }
                >
                  <Route path="message-board" element={<MessageBoardPage />} />
                </Route>

                <Route element={<GuardedRoutes accessingRight={ApplicationRight.Cases_Management}/>}>
                  <Route path="cases" element={<PatientRequestPage />} />
                </Route>

                <Route element={<GuardedRoutes accessingRight={ApplicationRight.Staff_Management_Access} />}>
                  <Route path="staff/group" element={<StaffGroupPage/>}/>
                  <Route path="staff/search" element={<StaffSearchPage />} />
                  <Route path="staff/create" element={<StaffCreatePage />} />
                </Route>
                <Route element={<GuardedRoutes accessingRight={ApplicationRight.Staff_Schedule_Access} />}>
                  <Route path="staff/schedule" element={<StaffSchedulePage />} />
                </Route>

                <Route element={<GuardedRoutes accessingRight={ApplicationRight.Orders_Tracking} />}>
                  <Route path="/procurement/" element={<ProcurementOrderPage />}>
                    <Route path="shipment" element={<ShipmentPage />} />
                    <Route path="tracking" element={<TrackingPage />} />
                  </Route>
                </Route>

                <Route
                  element={
                    <GuardedRoutes
                      accessingRight={[
                        ApplicationRight.Assigned_Opportunities_Access,
                        ApplicationRight.All_Opportunities_Access,
                      ]}
                      or
                    />
                  }
                >
                  <Route path="/opportunities-kanban" element={<OpportunitiesPage />} />
                </Route>

                <Route element={<GuardedRoutes accessingRight={ApplicationRight.Appointment_Read_Access} />}>
                  <Route path="appointment-management/by-location" element={<AppointmentCalendarByLocationPage />} />
                  <Route path="appointment-management/by-provider" element={<AppointmentCalendarByProviderPage />} />
                </Route>

                <Route element={<GuardedRoutes accessingRight={ApplicationRight.Product_Management_Access} />}>
                  <Route path="product-management" element={<ProductsPage />} />
                </Route>

                <Route element={<GuardedRoutes accessingRight={ApplicationRight.Biomarker_Management_Access} />}>
                  <Route path="biomarker-management" element={<BiomarkerManagementPage />} />
                </Route>

                <Route
                  element={
                    <GuardedRoutes
                      accessingRight={[ApplicationRight.Treatment_Review, ApplicationRight.Treatment_Creation]}
                      or
                    />
                  }
                >
                  {!!user?.rightList && (
                    <Route
                      path="treatments"
                      element={
                        user.rightList.includes(ApplicationRight.Treatment_Review) ? (
                          <TreatmentsNeedMyReviewPage />
                        ) : user.rightList.includes(ApplicationRight.Treatment_Creation) ? (
                          <MyTreatmentSubmissionsPage />
                        ) : (
                          <></>
                        )
                      }
                    />
                  )}
                </Route>
              </Routes>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default App;

import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Alert, Avatar, Col, FloatButton, Popover, Row, Spin, Statistic, Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPatient } from '../../stores/current-patient.store';
import { getPatientInfoById } from './service';
import { setCurrentLabResult } from '../../stores/current-lab-result.store';
import { setTreatmentCheatsheet } from '../../stores/treatment-cheatsheet.store';
import { CommentOutlined, MinusCircleTwoTone } from '@ant-design/icons';
import MessageStreamDisplayComponent from '../message-board/message-stream-display.component';
import MessageSendingComponent from '../message-board/message-sending.component';
import { ApplicationRight } from '../../share/RightList';
import { setTreatmentPlans } from '../../stores/patient-treatment-plan.store';

const PatientPage = () => {
  const [patientCardInfo, setPatientCardInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showChatButton, setShowChatButton] = useState(false);
  const [showChatBox, setShowChatBox] = useState(false);
  const [chatBoxContainer, setChatBoxContainer] = useState(null);
  const [showChatButtonDot, setShowChatButtonDot] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { patientId } = useParams();
  const currentPatient = useSelector((state) => state.currentPatient);
  const ongoingConversation = useSelector((state) => state.ongoingConversation);
  const user = useSelector((state) => state.authenticatedUser);

  const tabs = [
    {
      text: 'Details',
      route: 'details',
      exist: user.rightList.includes(ApplicationRight.Patient_Detail_Access),
    },
    {
      text: 'Medical Notes',
      route: 'medical-notes',
      exist: user.rightList.includes(ApplicationRight.Medical_Notes_Access),
    },
    {
      text: 'Lab Info',
      route: 'lab-info',
      exist: user.rightList.includes(ApplicationRight.Lab_Info_Access),
    },
    {
      text: 'Cases',
      route: 'cases',
      exist: user.rightList.includes(ApplicationRight.Lab_Info_Access),
    },
    {
      text: 'Treatment',
      route: 'treatment',
      exist:
        user.rightList.includes(ApplicationRight.Treatment_Creation) ||
        user.rightList.includes(ApplicationRight.Treatment_Review),
    },
    {
      text: 'BioID Brief',
      route: 'bioid-brief',
      exist: user.rightList.includes(ApplicationRight.BioID_Brief_Access),
    },
    {
      text: 'Orders',
      route: 'orders',
      exist: user.rightList.includes(ApplicationRight.Orders_Tracking),
    },
    {
      text: 'Appointment',
      route: 'appointment',
      exist: user.rightList.includes(ApplicationRight.Appointment_Read_Access),
    },
    {
      text: 'Files',
      route: 'files',
      exist: user.rightList.includes(ApplicationRight.Files_Access),
    },
    {
      text: 'Patient Advocate Group',
      route: 'pa-group',
      exist: user.rightList.includes(ApplicationRight.PA_Group_Access),
    },
    {
      text: 'Patient Consent',
      route: 'consent',
      exist: user.rightList.includes(ApplicationRight.Patient_Detail_Access),
    },
  ];

  if (!location.pathname.split('/')[3]) {
    const firstAccessibleRoute = tabs.find((t) => t.exist);
    if (firstAccessibleRoute) {
      navigate(firstAccessibleRoute.route);
    } else {
      navigate('/403');
    }
  }

  useEffect(() => {
    (async () => {
      if (!currentPatient || (!currentPatient.status && currentPatient.patientId !== patientId)) {
        const patientDataRequest = await getPatientInfoById(patientId);
        dispatch(
          setCurrentPatient({
            ...patientDataRequest.data.patient,
            allLabResults: patientDataRequest.data.allLabResults,
            currentLabReportPDF: patientDataRequest.data.currentLabReportPDF,
            currentLabReportPDFFileName: patientDataRequest.data.currentLabReportPDFFileName,
          })
        );
        dispatch(setTreatmentPlans(patientDataRequest.data.treatments || []));
        dispatch(setCurrentLabResult(patientDataRequest.data.currentLabResult || []));
        dispatch(setTreatmentCheatsheet(patientDataRequest.data.treatmentCheatsheet));
      } else {
        setLoading(false);
        setPatientCardInfo([
          {
            key: 'Type',
            value: currentPatient.profile.sfId ? 'Patient' : 'Lead',
          },
          {
            key: 'App User',
            value: currentPatient.isAppuser ? 'Yes' : 'No',
          },
          {
            key: 'Phone',
            value: currentPatient.profile.phone,
          },
          {
            key: 'Physician',
            value: currentPatient.profile.primaryCarePhysician,
          },
          {
            key: 'Primary Service Location',
            value: currentPatient.profile.primaryServiceLocation,
          },
          {
            key: 'Account Owner',
            value: currentPatient.profile.ownerId,
          },
        ]);
        if (ongoingConversation.patient) {
          if (ongoingConversation.patient.patientId === currentPatient.patientId) {
            setShowChatButton(true);
          }
        }
      }
    })();
  }, [currentPatient]);

  return (
    <>
      {!loading ? (
        location.pathname.split('/')[3] === 'new-appointment' ? (
          <Outlet />
        ) : (
          <div
            style={{
              background: '#F0F2F5 0% 0% no-repeat padding-box',
              padding: 32,
            }}
          >
            <h2>Patients</h2>
            <Row
              align="middle"
              style={{
                height: 127,
                background: 'white',
                padding: '0 12px',
                border: '1px solid white',
                borderRadius: 10,
              }}
              gutter={12}
            >
              <Col span={2} style={{ textAlign: 'center' }}>
                <Avatar size={87} src={currentPatient.profile.avatar} />
              </Col>
              <Col span={22} style={{ textAlign: 'center' }}>
                <Row>
                  <h4>
                    {currentPatient.profile.firstName}&nbsp;{currentPatient.profile.lastName}
                  </h4>
                </Row>
                <Row align="middle" justify="space-between">
                  {patientCardInfo.map((summary, i) => (
                    <Statistic
                      title={<div style={{ textAlign: 'left' }}>{summary.key}</div>}
                      value={summary.value}
                      formatter={(value) => value || ''}
                      valueStyle={{ fontSize: 14, textAlign: 'left' }}
                      key={i}
                    />
                  ))}
                </Row>
              </Col>
            </Row>
            {currentPatient && currentPatient.profile?.nonBinaryGender && (
              <Alert
                style={{ marginTop: 12 }}
                message="Some features will be unavailable due to patient's non-binary gender"
                type="error"
              />
            )}
            {!currentPatient.status && currentPatient.status !== 'prospect-patient' && (
              <div>
                <Tabs
                  activeKey={location.pathname.split('/')[3] || 'details'}
                  onTabClick={async (key) => {
                    navigate(key);
                  }}
                  style={{ marginTop: '12px' }}
                  items={tabs
                    .filter((m) => m.exist)
                    .map((tab) => {
                      return {
                        key: tab.route,
                        label: tab.text,
                      };
                    })}
                />
                <Outlet />
              </div>
            )}
          </div>
        )
      ) : (
        <div className="page-loader">
          <Spin size="large" />
        </div>
      )}
      {showChatButton && (
        <Popover
          trigger="click"
          placement={'leftTop'}
          open={showChatBox}
          content={
            <>
              <div
                style={{ height: 343, width: 433, overflowY: 'auto', paddingTop: 8, paddingLeft: 12 }}
                ref={setChatBoxContainer}
              >
                <MessageStreamDisplayComponent chatBoxContainer={chatBoxContainer} />
              </div>
              <MessageSendingComponent />
            </>
          }
          title={
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 12 }}>
              <div>
                Messages with {currentPatient.profile.firstName} {currentPatient.profile.lastName}
              </div>
              <div>
                <MinusCircleTwoTone onClick={() => setShowChatBox(false)} />
              </div>
            </div>
          }
        >
          <FloatButton
            badge={{ dot: showChatButtonDot }}
            type={'primary'}
            icon={<CommentOutlined />}
            onClick={() => {
              setShowChatBox(!showChatBox);
              setShowChatButtonDot(false);
            }}
          />
        </Popover>
      )}
    </>
  );
};

export default PatientPage;

import { Avatar, Button, message, Modal, Select, Tag, Tooltip, Typography, Upload } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import TextArea from 'antd/es/input/TextArea';
import bioApi from '../../../api/bioApi';
import { UPLOAD_CONTENT_URL } from '../../../api/URLs';
import { UploadOutlined } from '@mui/icons-material';
import { searchGroupStaff } from '../../staff/staff-search.service';
import { updateCase } from '../service';
import moment from 'moment';
import GroupsIcon from '@mui/icons-material/Groups';
import { casePriorityOptions, caseResolveCaseOptions, caseStatusOptions } from '../../../share/CaseSystem';
import SymptonGoalTracker from './sympton-goal-tracker.component';
import { useDispatch, useSelector } from 'react-redux';
import { updateCurrentPatient } from '../../../stores/current-patient.store';
import { checkIsOverlapedDate, checkValueInArrayObject, getAppointments } from '../../patient/service';

const ViewEditCaseModal = ({ modalOpen, setModalOpen, fetchPetientRequest, all, caseRecord, caseTypes }) => {
    let currentPatient = useSelector((state) => state.currentPatient);

    const defaultCaseValue = {
        type: "",
        priority: "",
        message: "",
        note: "",
        resolveCase: "",
        status: "new",
        assignee: [],
        cc: [],
        files: [],
        appointmentId: "",
        symptomsAndGoalsTracker: {
            sleep: "None",
            weight: "None",
            energy: "None",
            sexual: "None",
            cognitive: "None",
            healing: "None"
        }
    }

    const dispatch = useDispatch();
    const symptonTrackerRef = useRef(null);
    const [errorField, setErrorField] = useState([]);
    const [imagePreviewModal, setImagePreviewModal] = useState(null);
    const [fileList, setFileList] = useState([])
    const [submitLoading, setSubmitLoading] = useState(false);
    const [assignSearchResult, setAssignSearchResult] = useState([]);
    const [ccSearchResult, setCCSearchResult] = useState([]);
    const [tempCaseForm, setTempCaseForm] = useState(defaultCaseValue);
    const [caseForm, setCaseForm] = useState(defaultCaseValue);
    const [isChanged, setIsChanged] = useState(false);
    const [appointmentData, setAppointmentData] = useState([]);
    const [apptValue, setApptValue] = useState('');

    const getPatientAppointments = async (patientId) => {
        if (patientId) {
            let res = await getAppointments({ patientId: patientId });
            if (res.status === 200 && res.data) {
                let newApptData = res.data.filter((item) => ["Booked"].includes(item.status) && checkIsOverlapedDate(item.start));
                let existingData = res.data.find((item) => item.appointmentId === caseRecord.appointmentId);
                setAppointmentData(newApptData)

                if (checkValueInArrayObject(newApptData, caseRecord.appointmentId)) {
                    setApptValue(caseRecord.appointmentId)
                } else if (existingData) {
                    setApptValue(`${moment(existingData.start).tz(existingData.location.timezone).format('MM/DD/YYYY | hh:mma (z)')} - ${existingData.service.serviceName}[${existingData.service.type}]`)
                } else {
                    setApptValue('')
                }
            }
        } else {
            setAppointmentData([])
        }
    }

    useEffect(() => {
        let existingData = {
            type: caseRecord?.type ? caseRecord.type : "",
            priority: caseRecord?.priority ? caseRecord.priority : "",
            message: caseRecord?.comments ? caseRecord.comments : "",
            note: caseRecord?.noteFromStaff ? caseRecord.noteFromStaff : "",
            resolveCase: caseRecord?.resolveCase ? caseRecord.resolveCase : "",
            status: caseRecord?.status ? caseRecord.status : "new",
            assignee: caseRecord?.assignee ? caseRecord.assignee : [],
            cc: caseRecord?.cc ? caseRecord.cc : [],
            files: caseRecord?.files ? caseRecord.files : [],
            appointmentId: caseRecord?.appointmentId ? caseRecord.appointmentId : ""
        }

        let symptomsAndGoalsTracker = defaultCaseValue.symptomsAndGoalsTracker
        if (currentPatient?.patientId === caseRecord?.patientId) {
            symptomsAndGoalsTracker = currentPatient?.symptomsAndGoalsTracker;
        } else if (caseRecord?.symptomsAndGoalsTracker) {
            symptomsAndGoalsTracker = caseRecord?.symptomsAndGoalsTracker;
        }

        existingData["symptomsAndGoalsTracker"] = {
            sleep: symptomsAndGoalsTracker?.sleep || "None",
            weight: symptomsAndGoalsTracker?.weight || "None",
            energy: symptomsAndGoalsTracker?.energy || "None",
            sexual: symptomsAndGoalsTracker?.sexual || "None",
            cognitive: symptomsAndGoalsTracker?.cognitive || "None",
            healing: symptomsAndGoalsTracker?.healing || "None"
        };

        if (caseRecord?.assignee && caseRecord?.assigneesDetails) {
            setAssignSearchResult(caseRecord?.assigneesDetails)
        }

        if (caseRecord?.cc && caseRecord?.ccDetails) {
            setCCSearchResult(caseRecord?.ccDetails);
        }

        if (caseRecord?.files && caseRecord?.files.length) {
            let newFile = [];
            newFile = caseRecord.files.map((item, index) => (
                {
                    uid: index,
                    name: item.name,
                    status: 'done',
                    url: item.url,
                    type: item.fileType
                }
            ))
            setFileList([...newFile]);
        } else {
            setFileList([])
        }

        if (caseRecord?.patientId) {
            (async () => {
                await getPatientAppointments(caseRecord?.patientId);
            })();
        }

        setTempCaseForm({
            ...tempCaseForm,
            ...existingData
        })
        setCaseForm({
            ...caseForm,
            ...existingData
        })
    }, [caseRecord, modalOpen])

    const handleAssigneeSearch = (newValue) => {
        if (newValue && newValue.length > 2) {
            setTimeout(async () => {
                const result = await searchGroupStaff('general-matching', newValue);
                setAssignSearchResult(result.data);
            }, 300);
        } else {
            setAssignSearchResult([]);
        }
    }

    const handleCCSearch = (newValue) => {
        if (newValue && newValue.length > 2) {
            setTimeout(async () => {
                const result = await searchGroupStaff('general-matching', newValue);
                setCCSearchResult(result.data);
            }, 300);
        } else {
            setCCSearchResult([]);
        }
    }

    const fieldErrors = () => {
        let errors = [];
        if (!caseForm.assignee.length) {
            errors.push('Assign to not allow to be empty');
        }
        if (!caseForm.type.length) {
            errors.push('Case type not allow to be empty');
        }
        if (!caseForm.priority.length) {
            errors.push('Priority not allow to be empty');
        }
        if (!caseForm.message.length) {
            errors.push('Message not allow to be empty');
        }
        if (!caseForm.status.length) {
            errors.push('Status not allow to be empty');
        }
        if (caseForm.type === "appointment-adjustment" && !caseForm.appointmentId) {
            errors.push('Please select an appointment');
        }

        let symptonFormError = symptonTrackerRef?.current?.validateSymptonForm();
        if (symptonFormError?.length > 0) {
            errors = errors.concat(symptonFormError);
        }

        setErrorField([...errors]);
    }

    const handleSelectAssignee = async (value) => {
        setCaseForm({ ...caseForm, assignee: value ? value : "" })
    }

    const handleSelectCC = async (value) => {
        setCaseForm({ ...caseForm, cc: value ? value : "" })
    }

    const handleSelectCaseType = async (value) => {
        let data = {
            type: value ? value : ""
        }
        if (value === 'medical-reaction') {
            data['priority'] = 'urgent'
        }
        setCaseForm({ ...caseForm, ...data })
    }

    const handleSelectPriority = async (value) => {
        setCaseForm({ ...caseForm, priority: value ? value : "" })
    }

    const handleSelectResolveCase = async (value) => {
        setCaseForm({ ...caseForm, resolveCase: value ? value : "" })
    }

    const handleSelectStatus = async (value) => {
        setCaseForm({ ...caseForm, status: value ? value : "" })
    }

    const refreshFormValue = async () => {
        setCaseForm(tempCaseForm);
    }

    const modalError = (title, content) => {
        Modal.error({
            title: title,
            content: (
                <div>{content}</div>
            )
        });
    }

    const modalSuccess = (title, content) => {
        Modal.success({
            title: title,
            content: (
                <div>{content}</div>
            )
        })
    }

    const handleUpdate = async (action) => {
        let finalCaseForm = caseForm;
        if (action && action === 'reopen') {
            finalCaseForm = { ...finalCaseForm, status: 'open' };
        }
        setSubmitLoading(true);
        try {
            await updateCase(caseRecord._id, finalCaseForm).then((res) => {
                setSubmitLoading(false);
                if (res.data) {
                    if (res.data.status === 500) {
                        modalError("Failed", "Something went wrong. Please try again later.");
                    }
                    if (res.data.status === 200) {
                        modalSuccess("Success", res.data.message);
                        setModalOpen(false);
                        refreshFormValue();
                        fetchPetientRequest();
                        if (currentPatient.patientId === caseRecord.patientId) {
                            dispatch(
                                updateCurrentPatient({
                                    key: 'symptomsAndGoalsTracker',
                                    value: caseForm.symptomsAndGoalsTracker,
                                })
                            );
                        }
                    }
                }
            }).catch((err) => {
                modalError(err.message, err.response.data.message);
                setSubmitLoading(false);
            })
        } catch (err) {
            modalError(err.message, err.response.data.message);
            setSubmitLoading(false);
        }
    }

    useEffect(() => {
        let newFileList = [];
        if (fileList.length) {
            newFileList = fileList.map((item) => ({
                name: item.name,
                url: item.url,
                fileType: item.type
            }))
        }
        setCaseForm({ ...caseForm, files: newFileList })
    }, [fileList])

    useEffect(() => {
        const hasChanges = Object.keys(tempCaseForm).some(
            (key) =>
                JSON.stringify(caseForm[key]) !== JSON.stringify(tempCaseForm[key])
        );
        setIsChanged(hasChanges);
        fieldErrors();
    }, [caseForm]);

    const onModalClose = async () => {
        let title, okText, cancelText, onOk, onCancel;
        if (isChanged) {
            title = 'Closing now will discard all changes you just made';
            okText = 'Close';
            cancelText = 'Stay';
            onOk = () => {
                refreshFormValue();
                setModalOpen(false);
            };
            Modal.confirm({
                title: (
                    <p>
                        <div>{title}</div>
                        <Typography.Text type={'secondary'}>Click outside to dismiss this dialog</Typography.Text>
                    </p>
                ),
                okText,
                onOk,
                maskClosable: true,
                footer: (_, { OkBtn }) => (
                    <>
                        <Button
                            onClick={() => {
                                (onCancel ? onCancel : () => { })();
                                Modal.destroyAll();
                            }}
                        >
                            {cancelText}
                        </Button>
                        <OkBtn />
                    </>
                ),
            });
        } else {
            setModalOpen(false)
        }
    }

    const uploadProps = {
        name: 'file',
        maxCount: 5,
        action: bioApi.defaults.baseURL + UPLOAD_CONTENT_URL,
        showUploadList: true,
        accept: ".png,.jpg,.jpeg,.pdf,.doc,.docx",
        fileList: fileList,
        onChange(info) {
            const { file, fileList: newFileList } = info;

            if (file.status === 'removed') {
                let newFile = [];
                newFile = fileList.filter((item) => item.uid !== file.uid)
                setFileList([...newFile]);
            }

            if (file.status === 'uploading') {
                setFileList(newFileList);
            }

            if (file.status === "done") {
                const responseData = file.response; // Response from server
                const updatedFileList = newFileList.map((item) => {
                    if (item.uid === file.uid) {
                        return {
                            ...item,
                            url: responseData.data,
                        };
                    }
                    return item;
                });

                setFileList(updatedFileList);
                message.success(`${file.name} uploaded successfully.`);
            } else if (file.status === "error") {
                message.error(`${file.name} upload failed.`);
            }
        },
        withCredentials: true,
    };

    return (
        <Modal
            open={modalOpen}
            centered
            width={'95%'}
            footer={null}
            destroyOnClose={true}
            onCancel={onModalClose}
            maskClosable={false}
        >
            <div className='d-flex flex-column gap-4 case-form'>
                <h5>
                    Edit case
                </h5>
                <div className='row g-5'>
                    <div className='col-xl-6 col-12 d-flex flex-column gap-3'>
                        <div className='row'>
                            <div className='col-md-3 col-12'>
                                <label>Case ID:</label>
                            </div>
                            <div className='col-md-9 col-12'>
                                <p>{caseRecord && caseRecord.caseId ? caseRecord.caseId : ""}</p>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-3 col-12'>
                                <label>Date created:</label>
                            </div>
                            <div className='col-md-9 col-12'>
                                <p>{caseRecord && caseRecord.creationTS ? moment(caseRecord.creationTS).format('MMMM Do, YYYY hh:mm A') : ""}</p>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-3 col-12'>
                                <label>Created by:</label>
                            </div>
                            <div className='col-md-9 col-12'>
                                <p>{caseRecord && caseRecord.createdBy && caseRecord.createdBy.name ? caseRecord.createdBy.name : ""}</p>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-3 col-12'>
                                <label>Patient Name:</label>
                            </div>
                            <div className='col-md-9 col-12'>
                                <p>{caseRecord && caseRecord.patientInfo && caseRecord.patientInfo.name ? caseRecord.patientInfo.name : ""}</p>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-3 col-12'>
                                <label><span style={{ color: "red" }}>*</span>Assign to:</label>
                            </div>
                            <div className='col-md-9 col-12'>
                                <Select
                                    mode="multiple"
                                    showSearch
                                    placeholder="Search by Staff's Name, Email, Staff ID"
                                    size="large"
                                    style={{ minWidth: 420 }}
                                    defaultActiveFirstOption={false}
                                    filterOption={false}
                                    defaultValue={caseForm.assignee}
                                    onSearch={handleAssigneeSearch}
                                    onChange={handleSelectAssignee}
                                    optionLabelProp="label"
                                    allowClear
                                >
                                    {(assignSearchResult || []).map((s) => (
                                        s?.type === "individual" ? (
                                            <Select.Option value={s.staffId} label={s.fullName}>
                                                <div className='d-flex flex-row align-items-center justify-content-between'>
                                                    <div className="d-flex align-items-center">
                                                        <Avatar size="30" src={s.coverImage} style={{ marginRight: 10 }} />
                                                        <div>
                                                            <div>{s.fullName}</div>
                                                            <div>{s.staffId}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Select.Option>
                                        ) : s?.type === "group" ? (
                                            <Select.Option value={s.groupId} label={s.groupName}>
                                                <div className='d-flex flex-row align-items-center justify-content-between'>
                                                    <div className="d-flex align-items-center">
                                                        <GroupsIcon style={{ marginRight: 10 }} />
                                                        <div>
                                                            <div>{s.groupName}</div>
                                                            <div>{s.groupId}</div>
                                                        </div>
                                                    </div>
                                                    <Tag color="default">Group</Tag>
                                                </div>
                                            </Select.Option>
                                        ) : null
                                    ))}
                                </Select>
                            </div>
                        </div>
                        <div className='row align-items-center'>
                            <div className='col-md-3 col-12'>
                                <label>
                                    CC:
                                </label>
                            </div>
                            <div className='col-md-9 col-12'>
                                <Select
                                    mode="multiple"
                                    showSearch
                                    placeholder="Search by Staff's Name, Email, Staff ID"
                                    size="large"
                                    style={{ minWidth: 420 }}
                                    defaultActiveFirstOption={false}
                                    filterOption={false}
                                    defaultValue={caseForm.cc}
                                    onSearch={handleCCSearch}
                                    onChange={handleSelectCC}
                                    optionLabelProp="label"
                                    allowClear
                                >
                                    {(ccSearchResult || []).map((s) => (
                                        s?.type === "individual" ? (
                                            <Select.Option value={s.staffId} label={s.fullName}>
                                                <div className='d-flex flex-row align-items-center justify-content-between'>
                                                    <div className="d-flex align-items-center">
                                                        <Avatar size="30" src={s.coverImage} style={{ marginRight: 10 }} />
                                                        <div>
                                                            <div>{s.fullName}</div>
                                                            <div>{s.staffId}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Select.Option>
                                        ) : s?.type === "group" ? (
                                            <Select.Option value={s.groupId} label={s.groupName}>
                                                <div className='d-flex flex-row align-items-center justify-content-between'>
                                                    <div className="d-flex align-items-center">
                                                        <GroupsIcon style={{ marginRight: 10 }} />
                                                        <div>
                                                            <div>{s.groupName}</div>
                                                            <div>{s.groupId}</div>
                                                        </div>
                                                    </div>
                                                    <Tag color="default">Group</Tag>
                                                </div>
                                            </Select.Option>
                                        ) : null
                                    ))}
                                </Select>
                            </div>
                        </div>
                        <div className='row align-items-center'>
                            <div className='col-md-3 col-12'>
                                <label><span style={{ color: "red" }}>*</span>Case type:</label>
                            </div>
                            <div className='col-md-9 col-12'>
                                <Select
                                    showSearch
                                    size="large"
                                    placeholder="Search to Select"
                                    style={{ minWidth: 420 }}
                                    value={caseForm.type}
                                    onChange={handleSelectCaseType}
                                    options={caseTypes}
                                    allowClear
                                />
                            </div>
                        </div>
                        {
                            caseForm.type && caseForm.type === "appointment-adjustment" ?
                                <div className='row align-items-center'>
                                    <div className='col-md-3 col-12'>
                                    </div>
                                    <div className='col-md-9 col-12'>
                                        <div className='d-flex flex-column'>
                                            <label><span style={{ color: "red" }}>*</span>Seleted Appointment:</label>
                                            <Select
                                                placeholder="Select Appointment"
                                                size="large"
                                                style={{ minWidth: 420, maxWidth: 420 }}
                                                defaultActiveFirstOption={false}
                                                filterOption={false}
                                                value={apptValue}
                                                onChange={(value) => {
                                                    setCaseForm({ ...caseForm, appointmentId: value ? value : "" })
                                                }}
                                                optionLabelProp="label"
                                                allowClear
                                            >
                                                {(appointmentData || []).map((item) => {
                                                    let label = `${moment(item.start).tz(item.location.timezone).format('MM/DD/YYYY | hh:mma (z)')} - ${item.service.serviceName}[${item.service.type}]`
                                                    return (
                                                        <Select.Option value={item.appointmentId} label={label}>
                                                            {label}
                                                        </Select.Option>
                                                    )
                                                })}
                                            </Select>
                                        </div>
                                    </div>
                                </div>
                                : null
                        }
                        <div className='row align-items-center'>
                            <div className='col-md-3 col-12'>
                                <label><span style={{ color: "red" }}>*</span>Priority:</label>
                            </div>
                            <div className='col-md-9 col-12'>
                                <Select
                                    showSearch
                                    size="large"
                                    placeholder="Search to Select"
                                    style={{ minWidth: 420 }}
                                    value={caseForm.priority}
                                    onChange={handleSelectPriority}
                                    options={casePriorityOptions()}
                                    allowClear
                                />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-3 col-12'>
                                <label><span style={{ color: "red" }}>*</span>Message:</label>
                            </div>
                            <div className='col-md-9 col-12'>
                                <TextArea value={caseForm.message} onChange={(e) => setCaseForm({ ...caseForm, message: e.target.value })} rows={4} />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-3 col-12'>
                                <label>
                                    Add comment:
                                </label>
                            </div>
                            <div className='col-md-9 col-12'>
                                <TextArea value={caseForm.note} onChange={(e) => {
                                    let data = {
                                        note: e.target.value
                                    }
                                    if (e.target.value.length > 0) {
                                        data['status'] = 'open'
                                    }
                                    setCaseForm({ ...caseForm, ...data })
                                }} rows={4} />
                            </div>
                        </div>
                        <div className='row align-items-center'>
                            <div className='col-md-3 col-12'>
                                <label>
                                    Action taken to resolve case:
                                </label>
                            </div>
                            <div className='col-md-9 col-12'>
                                <Select
                                    showSearch
                                    size="large"
                                    placeholder="Search to Select"
                                    style={{ minWidth: 420 }}
                                    value={caseForm.resolveCase}
                                    onChange={handleSelectResolveCase}
                                    options={caseResolveCaseOptions()}
                                    allowClear
                                />
                            </div>
                        </div>
                        <div className='row align-items-center'>
                            <div className='col-md-3 col-12'>
                                <label><span style={{ color: "red" }}>*</span>Status:</label>
                            </div>
                            <div className='col-md-9 col-12'>
                                <Select
                                    showSearch
                                    size="large"
                                    placeholder="Search to Select"
                                    style={{ minWidth: 420 }}
                                    value={caseForm.status}
                                    onChange={handleSelectStatus}
                                    options={caseStatusOptions()}
                                    allowClear
                                    disabled={caseRecord?.status === "complete"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='col-xl-5 col-12 d-flex flex-column gap-5'>
                        {
                            caseRecord?.modifiedTS && caseRecord.modifiedBy ? (
                                <div className='row'>
                                    <div className='col-md-3 col-12'>
                                        <label>
                                            Last modified:
                                        </label>
                                    </div>
                                    <div className='col-md-9 col-12'>
                                        <p>{caseRecord.modifiedBy.name} - {moment(caseRecord.modifiedTS).format('MMMM Do, YYYY hh:mm A')}</p>
                                    </div>
                                </div>
                            ) : ""
                        }
                        <div className='row'>
                            <div className='col-3'>
                                <label>
                                    Upload file/images:
                                </label>
                            </div>
                            <div className='col-9'>
                                <Upload
                                    {...uploadProps}
                                >
                                    {
                                        fileList.length >= 5 ? null : (
                                            <Button icon={<UploadOutlined />}>
                                                Upload
                                            </Button>
                                        )
                                    }
                                </Upload>
                            </div>
                        </div>
                        <div style={{ maxWidth: '450px' }}>
                            <SymptonGoalTracker
                                ref={symptonTrackerRef}
                                mergedForm={true}
                                onFieldsChange={(key, value) => {
                                    setCaseForm({ ...caseForm, symptomsAndGoalsTracker: { ...caseForm.symptomsAndGoalsTracker, [key]: value } });
                                    fieldErrors();
                                }}
                                data={caseForm?.symptomsAndGoalsTracker}
                            />
                        </div>
                    </div>
                </div>
                <div className='d-flex flex-row justify-content-center gap-3'>
                    <Tooltip title={errorField.join(', ')} color={'#ef5350'}
                        placement={'right'}>
                        <Button type="primary" size={'large'} disabled={!!errorField.length || !isChanged} onClick={() => handleUpdate()} loading={submitLoading}>
                            Update
                        </Button>
                    </Tooltip>
                    <Button type="default" size="large" onClick={() => onModalClose()}>Close</Button>
                </div>
            </div>
            <Modal
                open={!!imagePreviewModal}
                title={imagePreviewModal?.title}
                footer={null}
                onCancel={() => setImagePreviewModal(null)}
            >
                <img alt={imagePreviewModal?.title} style={{ width: '100%' }} src={imagePreviewModal?.src} />
            </Modal>
        </Modal>
    )
}

export default ViewEditCaseModal
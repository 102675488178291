import React from 'react';
import AllTreatmentsPlans from '../../../components/all-treatment-plans.component';

const TreatmentReviewPage = () => {
  return (
    <AllTreatmentsPlans displayCurrent={true} />
  );
};

export default TreatmentReviewPage;

import React, { useEffect, useState } from 'react';
import DataTable from '../../../components/data-table/data-table.component'
import moment from 'moment/moment';
import {
    AutoComplete,
    Badge,
    Input,
    Popover,
    Tooltip,
} from 'antd';
import { getCaseTypes, getPatientRequests } from '../service';
import { Link } from 'react-router-dom';
import { InfoCircleTwoTone, SearchOutlined } from '@ant-design/icons';
import { EditCalendar, LocalPharmacyOutlined, RateReview, PhoneIphone } from '@mui/icons-material';
import AppointmentDetail from '../../appointments/appointment-detail.component';
import { Button } from 'react-bootstrap';
import ExpandableText from '../../../components/expandable-text/expandable-text';
import DatePickerWithMoment from '../../../components/date/DatePickerWithMoment';
import { BiLinkExternal } from 'react-icons/bi';
import CreateCaseModal from './create-case-modal.component';
import ViewEditCaseModal from './view-edit-case-modal.component';
import { casePriorityData, caseResolveCaseData, caseStatusData } from '../../../share/CaseSystem';
import ViewCaseModal from './view-case-modal.component';

export const calculateElapsedHours = (modifiedTS) => {
    const modifiedDate = new Date(modifiedTS);
    const currentDate = new Date();
    const hoursElapsed = (currentDate - modifiedDate) / (1000 * 60 * 60); // Convert ms to hours

    // Determine SLA color based on hours elapsed
    const caseSLA = [
        { color: 'green', hours: { min: 0, max: 24 } },
        { color: 'yellow', hours: { min: 24, max: 48 } },
        { color: 'red', hours: { min: 48, max: null } },
    ];

    const status = caseSLA.find(
        (item) =>
            hoursElapsed >= item.hours.min &&
            (hoursElapsed < item.hours.max || item.hours.max === null)
    );

    return status ? status.color : 'unknown';
};

const PatientRequestList = ({ all, listSize, patientId }) => {
    const [tableData, setTableData] = useState([]);
    const [dataLoading, setDataLoading] = useState(true);
    const [keySearch, setKeySearch] = useState('');
    const [rangeDate, setRangeDate] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [viewModalOpen, setViewModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [caseRecord, setCaseRecord] = useState();
    const [caseTypes, setCaseTypes] = useState([]);
    const [caseTypesOptions, setCaseTypesOptions] = useState([]);

    const fetchCaseTypes = async () => {
        const requests = await getCaseTypes();
        if (requests && requests.data.status === 200 && requests.data.data) {
            let rsp = requests.data.data;
            let newCaseTypes = rsp.map((item) => ({
                text: item.text,
                value: item.value
            }))
            let options = rsp.map((item) => ({
                label: item.text,
                value: item.value
            }))
            setCaseTypes(newCaseTypes);
            setCaseTypesOptions(options);
        }
    }

    const [slaColor, setSlaColor] = useState('green');
    const [elapsedHours, setElapsedHours] = useState(0);

    const fetchPetientRequest = async () => {
        setDataLoading(true)
        let startDate = "";
        let endDate = "";
        if (rangeDate !== null && rangeDate.length === 2) {
            startDate = rangeDate[0];
            endDate = rangeDate[1];
        }
        let data = "";
        if (patientId) {
            data = patientId;
        } else {
            data = keySearch;
        }
        const requests = await getPatientRequests(data, startDate, endDate);
        setTableData(requests.data);
        setDataLoading(false);
    }

    const handleEnterKeySearch = (e) => {
        if (e.key === "Enter") {
            fetchPetientRequest();
        }
    };

    const selectRangeDate = async (value) => {
        setRangeDate(value);
    }

    useEffect(() => {
        if (keySearch || rangeDate || rangeDate === null) {
            fetchPetientRequest()
        }
    }, [keySearch, rangeDate])

    useEffect(() => {
        (async () => {
            fetchPetientRequest();
            fetchCaseTypes();
        })();
    }, []);

    let tableSetting = {
        columns: [
            {
                title: 'Date Created',
                dataIndex: ['creationTS'],
                render: (text) => moment(text).format('MMMM DD, YYYY'),
                sorter: (a, b) => new Date(a.creationTS).getTime() - new Date(b.creationTS).getTime(),
                defaultSortOrder: 'descend',
                width: 180,
            },
            {
                title: 'Patient Name',
                dataIndex: ['patientInfo', 'name'],
                filterable: true,
                render: (text, record) => (
                    <>
                        {
                            record.channel && record.channel === "admin" ? "" : (
                                <Tooltip title={'Patient requests via app'}>
                                    <PhoneIphone className='mb-1' style={{ marginRight: 8, fontSize: 'small' }} />
                                </Tooltip>
                            )
                        }
                        <Tooltip title={'To patient detail page'}>
                            <Link to={`/patient/${record.patientId}`} style={{ marginRight: 8 }}>
                                {text}
                            </Link>
                        </Tooltip>
                        <Popover
                            content={
                                <>
                                    <div>
                                        {record.patientInfo.gender}, {record.patientInfo.age}
                                    </div>
                                </>
                            }
                            title="Basic Info"
                        >
                            <InfoCircleTwoTone />
                        </Popover>
                    </>
                ),
                filterSearch: true,
                sorter: (a, b) => a.patientInfo.name.toLowerCase().localeCompare(b.patientInfo.name.toLowerCase()),
                width: 200,
            },
            {
                title: 'Case Type',
                dataIndex: ['type'],
                filterable: true,
                customFilterable: caseTypes,
                render: (text, record) => {
                    if (text && record) {
                        let type = caseTypes.find((item) => item.value === text.toLowerCase());
                        if (type && type.text) {
                            if (type.value === 'refill') {
                                return (
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <LocalPharmacyOutlined style={{ marginRight: 8, fontSize: 'small' }} />
                                        {type.text}
                                    </div>
                                );
                            } else if (type.value === 'treatment-review') {
                                return (
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <RateReview style={{ marginRight: 8, fontSize: 'small' }} />
                                        {type.text}
                                    </div>
                                );
                            } else if (type.value === 'appointment-adjustment') {
                                const appointment = {
                                    service: record.service,
                                    patient: {
                                        patientId: record.patientId,
                                        firstName: record.patientInfo.name.split(' ')[0],
                                        lastName: record.patientInfo.name.split(' ')[1],
                                    },
                                    location: record.location,
                                    staff: record.staff,
                                    start: record.appointmentStart,
                                    end: record.appointmentEnd,
                                };
                                return (
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Popover content={<AppointmentDetail appointment={appointment} />} placement={'right'}>
                                            <EditCalendar style={{ marginRight: 8, fontSize: 'small' }} />
                                            {type.text}
                                            <Link to={`/patient/${record.patientId}/appointment`} style={{ marginLeft: 8 }} target={'_blank'}>
                                                <BiLinkExternal />
                                            </Link>
                                        </Popover>
                                    </div>
                                );
                            } else {
                                return (
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        {type.text}
                                    </div>
                                )
                            }
                        }
                    }
                },
                sorter: (a, b) => a.type.toLowerCase().localeCompare(b.type.toLowerCase()),
                width: 300,
            },
            {
                title: 'Message Preview',
                dataIndex: ['comments'],
                render: (text) => <ExpandableText style={{ marginBottom: 0 }} text={text} maxChars={60} />,
                width: 300,
            },
            {
                title: 'Resolution',
                dataIndex: ['resolveCase'],
                render: (text) => {
                    if (text) {
                        let type = caseResolveCaseData.find((item) => item.value === text.toLowerCase());
                        if (type && type.text) {
                            return (
                                <ExpandableText style={{ marginBottom: 0 }} text={type.text} maxChars={60} />
                            )
                        }
                    }
                },
                filterable: true,
                customFilterable: caseResolveCaseData,
                sorter: (a, b) => a.status.toLowerCase().localeCompare(b.status.toLowerCase()),
                width: 200,
            },
            {
                title: 'Priority',
                dataIndex: ['priority'],
                render: (text) => {
                    if (text) {
                        let type = casePriorityData.find((item) => item.value === text.toLowerCase());
                        if (type && type.text) {
                            return (
                                <ExpandableText style={{ marginBottom: 0 }} text={type.text} maxChars={60} />
                            )
                        }
                    }
                },
                filterable: true,
                customFilterable: casePriorityData,
                sorter: (a, b) => a.status.toLowerCase().localeCompare(b.status.toLowerCase()),
                width: 200,
            },
            {
                title: 'Status',
                dataIndex: ['status'],
                render: (text) => {
                    if (text) {
                        let type = caseStatusData.find((item) => item.value === text.toLowerCase());
                        if (type && type.text) {
                            if (type.value === 'new') {
                                return <Badge status="success" text={type.text} />;
                            } else if (type.value === 'open') {
                                return <Badge status="processing" text={type.text} />;
                            } else if (type.value === 'complete') {
                                return <Badge status="default" text={type.text} />;
                            } else {
                                return text;
                            }
                        }
                    }
                },
                filterable: true,
                customFilterable: caseStatusData,
                sorter: (a, b) => a.status.toLowerCase().localeCompare(b.status.toLowerCase()),
                width: 200,
            },
            {
                title: 'Last Modified',
                render: (tdata) => {
                    if (tdata) {
                        calculateElapsedHours(tdata.modifiedTS);
                        return `${tdata.modifiedBy.name ? tdata.modifiedBy.name + " -" : ""} ${tdata.modifiedTS ? moment(tdata.modifiedTS).format('MMM DD, YYYY - hh:mma') : ""}`
                    }
                },
                width: 300,
            },
        ],
        numbered: true,
        numberedWidth: 50,
        operationColumnTitle: 'Action',
        scroll: { x: 2000 },
        sla: true,
        bordered: true,
        tableHeader: (
            <div>
                {
                    all && <h5>Cases</h5>
                }
                <div className='row g-3 align-items-end'>
                    <div className='col-xl-7 col-12'>
                        {
                            all ? (
                                <AutoComplete
                                    onChange={(value) => setKeySearch(value)}
                                    className='case-search-fields'
                                    onKeyDown={(e) => handleEnterKeySearch(e)}
                                >
                                    <Input
                                        size="large"
                                        placeholder="Search by Patient name or Case ID"
                                        suffix={
                                            <SearchOutlined onClick={() => fetchPetientRequest()} />
                                        }
                                        allowClear
                                    />
                                </AutoComplete>
                            ) : (
                                <h5>Case History</h5>
                            )
                        }
                    </div>
                    <div className='col-xl-5 col-12'>
                        <div className='row g-3 align-items-end'>
                            <div className='col-md-8 col-12'>
                                <div>
                                    <label>Date</label>
                                    <DatePickerWithMoment.RangePicker
                                        size={"large"}
                                        value={rangeDate}
                                        format={"MM/DD/YYYY"}
                                        placement="bottomLeft"
                                        className='w-100'
                                        onChange={selectRangeDate}
                                    />
                                </div>
                            </div>
                            <div className='col-md-4 col-12 d-flex justify-content-end'>
                                <Button onClick={() => setModalOpen(!modalOpen)} className='case-create-button'>Create new case</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ),
        size: 'middle',
        pagination: {
            pageSize: listSize ? listSize : 10
        },
        actions: {
            fixed: 'right',
            width: 150
        },
        customActions: [
            {
                text: 'View',
                whenPerform: async (record) => {
                    setViewModalOpen(true)
                    setCaseRecord(record)
                },
                shallNotRender: (record) => record.status !== 'complete',
            },
            {
                text: 'View',
                whenPerform: async (record) => {
                    setViewModalOpen(true)
                    setCaseRecord(record)
                },
                shallNotRender: (record) => record.status === 'complete',
            },
            {
                text: 'Edit',
                whenPerform: async (record) => {
                    setEditModalOpen(true)
                    setCaseRecord(record)
                },
                shallNotRender: (record) => record.status === 'complete',
            }
        ],
    };

    return (
        <>
            <DataTable settings={tableSetting} loading={dataLoading} data={tableData} />
            <CreateCaseModal modalOpen={modalOpen} setModalOpen={setModalOpen} fetchPetientRequest={fetchPetientRequest} all={all} caseTypes={caseTypesOptions} />
            <ViewCaseModal modalOpen={viewModalOpen} setModalOpen={setViewModalOpen} fetchPetientRequest={fetchPetientRequest} all={false} caseRecord={caseRecord} caseTypes={caseTypesOptions} />
            <ViewEditCaseModal modalOpen={editModalOpen} setModalOpen={setEditModalOpen} fetchPetientRequest={fetchPetientRequest} all={false} caseRecord={caseRecord} caseTypes={caseTypesOptions} />
        </>
    )
}

export default PatientRequestList
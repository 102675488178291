import bioApi from '../../api/bioApi';
import { STAFF_AUTO_COMPLETE_URL, STAFF_GROUP_CREATE_URL, STAFF_GROUP_DELETE_URL, STAFF_GROUP_LIST_URL, STAFF_GROUP_SEARCH_URL, STAFF_GROUP_UPDATE_URL, STAFF_LIST_URL, STAFF_SEARCH_URL } from '../../api/URLs';

export const listAllStaff = () => {
  return bioApi.get(STAFF_LIST_URL);
};

export const searchStaff = (option, query) => {
  const url = STAFF_SEARCH_URL.replace(':option', option).replace(':query', query);
  return bioApi.get(url);
};

export const autoCompleteStaff = (option, query) => {
  const url = STAFF_AUTO_COMPLETE_URL.replace(':option', option).replace(':query', query);
  return bioApi.get(url);
};

export const listAllStaffGroup = () => {
  return bioApi.get(STAFF_GROUP_LIST_URL);
}

export const searchGroupStaff = (option, query) => {
  const url = STAFF_GROUP_SEARCH_URL.replace(':option', option).replace(':query', query);
  return bioApi.get(url);
}

export const createGroupStaff = async (data) => {
  return await bioApi.post(STAFF_GROUP_CREATE_URL, data)
}

export const updateGroupStaff = async (id, data) => {
  const url = STAFF_GROUP_UPDATE_URL.replace(':id', id);
  return await bioApi.post(url, data);
}

export const deleteGroupStaff = async (id) => {
  const url = STAFF_GROUP_DELETE_URL.replace(':id', id);
  return await bioApi.post(url);
}
import { Avatar, Button, Input, Modal, Select, Tooltip, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { createGroupStaff, deleteGroupStaff, searchStaff, updateGroupStaff } from '../staff-search.service';

const StaffGroupViewEdit = ({ modalOpen, handleCloseModal, initLoadStaffGroup, viewEditModal }) => {
    const defaultCreateForm = {
        groupName: "",
        groupStaff: []
    }

    const [createForm, setCreateForm] = useState(defaultCreateForm);
    const [tempCreateForm, setTempCreateForm] = useState(defaultCreateForm);
    const [staffSearchResult, setStaffSearchResult] = useState([]);
    const [isChanged, setIsChanged] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);

    useEffect(() => {
        const hasChanges = Object.keys(tempCreateForm).some(
            (key) =>
                JSON.stringify(createForm[key]) !== JSON.stringify(tempCreateForm[key])
        );
        setIsChanged(hasChanges);
    }, [createForm]);

    useEffect(() => {
        let existingData = {
            groupName: viewEditModal?.record && viewEditModal?.record.groupName ? viewEditModal?.record.groupName : "",
            groupStaff: []
        }
        if (viewEditModal?.record && viewEditModal?.record.groupStaff) {
            existingData['groupStaff'] = viewEditModal?.record.groupStaff.map((item) => item.staffId)
        }
        if (viewEditModal?.record) {
            if (viewEditModal?.record.groupStaff) {
                setStaffSearchResult(viewEditModal?.record.groupStaff);
            }
        }

        setTempCreateForm({
            ...tempCreateForm,
            ...existingData
        })
        setCreateForm({
            ...createForm,
            ...existingData
        })
    }, [viewEditModal])

    let staffSearchDebounceTimeout;
    let currentStaff;

    const fieldErrors = () => {
        const errors = [];
        if (!createForm.groupName.length) {
            errors.push('Assign to not allow to be empty');
        }
        if (!createForm.groupStaff.length) {
            errors.push('Patient Name not allow to be empty');
        }
        return errors;
    };

    const handleStaffSearch = (newValue) => {
        if (newValue && newValue.length > 2) {
            if (staffSearchDebounceTimeout) {
                clearTimeout(staffSearchDebounceTimeout);
                staffSearchDebounceTimeout = null;
            }
            currentStaff = newValue;

            staffSearchDebounceTimeout = setTimeout(async () => {
                const result = await searchStaff('general-matching', newValue);
                if (currentStaff === newValue) {
                    setStaffSearchResult(result.data.staff);
                }
            }, 300);
        } else {
            setStaffSearchResult([]);
        }
    }

    const handleSelectStaff = async (value) => {
        setCreateForm({ ...createForm, groupStaff: value ? value : "" })
    }

    const refreshFormValue = async () => {
        setCreateForm(tempCreateForm);
    }

    const onModalClose = async () => {
        let title, okText, cancelText, onOk, onCancel;
        if (isChanged) {
            title = 'Closing now will discard all changes you just made';
            okText = 'Close';
            cancelText = 'Stay';
            onOk = () => {
                refreshFormValue();
                handleCloseModal();
            };
            Modal.confirm({
                title: (
                    <p>
                        <div>{title}</div>
                        <Typography.Text type={'secondary'}>Click outside to dismiss this dialog</Typography.Text>
                    </p>
                ),
                okText,
                onOk,
                maskClosable: true,
                footer: (_, { OkBtn }) => (
                    <>
                        <Button
                            onClick={() => {
                                (onCancel ? onCancel : () => { })();
                                Modal.destroyAll();
                            }}
                        >
                            {cancelText}
                        </Button>
                        <OkBtn />
                    </>
                ),
            });
        } else {
            handleCloseModal()
        }
    }

    const modalError = (title, content) => {
        Modal.error({
            title: title,
            content: (
                <div>{content}</div>
            )
        });
    }

    const modalSuccess = (title, content) => {
        Modal.success({
            title: title,
            content: (
                <div>{content}</div>
            )
        })
    }

    const handleSubmit = async () => {
        setSubmitLoading(true);
        try {
            await updateGroupStaff(viewEditModal?.record.groupId, createForm).then((res) => {
                setSubmitLoading(false);
                if (res.data) {
                    if (res.data.status === 500) {
                        modalError("Failed", res.data.message);
                    }
                    if (res.data.status === 200) {
                        modalSuccess("Success", res.data.message);
                        handleCloseModal();
                        refreshFormValue();
                        initLoadStaffGroup();
                    }
                }
            }).catch((err) => {
                modalError(err.message, err.response.data.message);
                setSubmitLoading(false);
            })
        } catch (err) {
            modalError(err.message, err.response.data.message);
            setSubmitLoading(false);
        }
    }

    const handleDelete = async () => {
        let title, okText, cancelText, onOk, onCancel;
        title = 'Delete Staff Group';
        okText = 'Yes';
        cancelText = 'No';
        onOk = async () => {
            try {
                await deleteGroupStaff(viewEditModal?.record.groupId).then((res) => {
                    setSubmitLoading(false);
                    if (res.data) {
                        if (res.data.status === 500) {
                            modalError("Failed", res.data.message);
                        }
                        if (res.data.status === 200) {
                            modalSuccess("Success", res.data.message);
                            handleCloseModal();
                            refreshFormValue();
                            initLoadStaffGroup();
                        }
                    }
                }).catch((err) => {
                    modalError(err.message, err.response.data.message);
                    setSubmitLoading(false);
                })
            } catch (err) {
                modalError(err.message, err.response.data.message);
                setSubmitLoading(false);
            }
        };
        Modal.confirm({
            title: (
                <p>
                    <div>{title}</div>
                    <Typography.Text type={'secondary'}>Are you sure to delete this staff group?</Typography.Text>
                </p>
            ),
            okText,
            onOk,
            maskClosable: true,
            footer: (_, { OkBtn }) => (
                <>
                    <OkBtn />
                    <Button
                        onClick={() => {
                            (onCancel ? onCancel : () => { })();
                            Modal.destroyAll();
                        }}
                    >
                        {cancelText}
                    </Button>
                </>
            ),
        });
    }

    return (
        <Modal
            open={modalOpen}
            centered
            footer={null}
            width={'50%'}
            destroyOnClose={true}
            onCancel={onModalClose}
            maskClosable={false}
        >
            <div className='d-flex flex-column gap-4'>
                <h5>
                    Staff group
                </h5>
                <div className='d-flex flex-column gap-3'>
                    <div className='row align-items-center'>
                        <div className='col-md-3 col-12'>
                            <span><span style={{ color: "red" }}>*</span>Group Name:</span>
                        </div>
                        <div className='col-md-9 col-12'>
                            <Input value={createForm.groupName} onChange={(e) => setCreateForm({ ...createForm, groupName: e.target.value })} />
                        </div>
                    </div>
                    <div className='row align-items-center'>
                        <div className='col-md-3 col-12'>
                            <span><span style={{ color: "red" }}>*</span>Group Member:</span>
                        </div>
                        <div className='col-md-9 col-12'>
                            <Select
                                mode="multiple"
                                showSearch
                                placeholder="Search by Staff's Name, Email, Staff ID"
                                size="large"
                                style={{ minWidth: 420 }}
                                defaultActiveFirstOption={false}
                                filterOption={false}
                                value={createForm.groupStaff}
                                onSearch={handleStaffSearch}
                                onChange={handleSelectStaff}
                                optionLabelProp="label"
                                allowClear
                                className='w-100'
                            >
                                {(staffSearchResult || []).map((s) => (
                                    <Select.Option value={s.staffId} label={s.fullName}>
                                        <div className="d-flex align-items-center">
                                            <Avatar size="30" src={s.coverImage} style={{ marginRight: 10 }} />
                                            <div>
                                                <div>{s.fullName}</div>
                                                <div>{s.staffId}</div>
                                            </div>
                                        </div>
                                    </Select.Option>
                                ))}
                            </Select>
                        </div>
                    </div>
                </div>
                <div className='d-flex flex-row justify-content-center gap-3'>
                    <Tooltip title={fieldErrors().join(', ')} color={'#ef5350'}
                        placement={'right'}>
                        <Button type="primary" size={'large'} disabled={!!fieldErrors().length || !isChanged} onClick={handleSubmit} loading={submitLoading}>
                            Update
                        </Button>
                    </Tooltip>
                    <Button danger size="large" onClick={handleDelete}>Delete</Button>
                </div>
            </div>
        </Modal>
    )
}

export default StaffGroupViewEdit
import { Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import moment from 'moment';
import { checkIsOverlapedDate, getAppointments } from '../../patient/service';

const VisitDisplay = () => {
    const currentPatient = useSelector((state) => state.currentPatient);
    const [visitReport, setVisitReport] = useState([
        {
            label: "Last visit:",
            value: ""
        },
        {
            label: "Next visit:",
            value: ""
        },
        {
            label: "Last lab draw:",
            value: ""
        },
        {
            label: "Next available lab draw:",
            value: ""
        },
        {
            label: "Most recent PA phone call:",
            value: ""
        },
        {
            label: "Next scheduled lab draw:",
            value: ""
        },
    ])

    const updateVisitReport = (labelToUpdate, newValue) => {
        setVisitReport(prevReport =>
            prevReport.map(item =>
                item.label === labelToUpdate
                    ? { ...item, value: newValue }
                    : item
            )
        );
    };

    const checkIsOverlapedAppointmentDateByZone = (appointmentData) => {
        let startTime = moment(appointmentData.start).tz(appointmentData.location.timezone);

        return moment().tz(appointmentData.location.timezone).diff(startTime) <= 0;
    }

    const getAppointmentOfPatient = async () => {
        const appointmentData = await getAppointments(currentPatient);
        if (appointmentData.data) {
            let lastVisit = appointmentData.data.filter(appointment => appointment.status === "Completed").sort((a, b) => new Date(b.start) - new Date(a.start))[0];
            let nextVisit = appointmentData.data.filter(appointment => appointment.status === "Booked" && checkIsOverlapedAppointmentDateByZone(appointment)).sort((a, b) => new Date(a.start) - new Date(b.start))[0];
            let nextLabDraw = appointmentData.data.filter(appointment => appointment.status === "Booked" && appointment.service.serviceName === "Lab Draw" && checkIsOverlapedAppointmentDateByZone(appointment)).sort((a, b) => new Date(a.start) - new Date(b.start))[0]

            if (lastVisit) {
                let startTime = moment(lastVisit.start).tz(lastVisit.location.timezone).format('MM/DD/YYYY | hh:mma (z)');
                updateVisitReport("Last visit:", startTime)
            }

            if (nextVisit) {
                let startTime = moment(nextVisit.start).tz(nextVisit.location.timezone);
                if (checkIsOverlapedDate(startTime)) {
                    updateVisitReport("Next visit:", startTime ? startTime.format('MM/DD/YYYY | hh:mma (z)') : "");
                }
            }

            if (nextLabDraw) {
                let startTime = moment(nextLabDraw.start).tz(nextLabDraw.location.timezone);
                if (checkIsOverlapedDate(startTime)) {
                    updateVisitReport("Next scheduled lab draw:", startTime ? startTime.format('MM/DD/YYYY | hh:mma (z)') : "");
                }
            }
        }

        if (currentPatient) {
            if (currentPatient.allLabResults) {
                let data = currentPatient.allLabResults.find((plr) => plr._id === currentPatient.currentLabReportId)?.collectionDate || '';
                updateVisitReport("Last lab draw:", data)
            }
            if (currentPatient.profile) {
                let mostRecentPAPhoneCall = currentPatient.profile.mostRecentPAPhoneCall;
                let availableNextLabDraw = currentPatient.profile.availableLabdraw;
                if (mostRecentPAPhoneCall) {
                    updateVisitReport("Most recent PA phone call:", mostRecentPAPhoneCall ? moment(mostRecentPAPhoneCall).format('MM/DD/YYYY') : "");
                }
                if (availableNextLabDraw) {
                    let startTime = moment(availableNextLabDraw).tz(currentPatient.profile.primaryServiceCenter.timezone);
                    if (checkIsOverlapedDate(startTime)) {
                        updateVisitReport("Next available lab draw:", startTime ? startTime.format('MM/DD/YYYY') : "");
                    }
                }
            }
        }
    };

    useEffect(() => {
        getAppointmentOfPatient();
    }, [])

    return (
        <div className='px-3 py-4' style={{ borderRadius: '10px', backgroundColor: 'white' }}>
            <div className='row g-4'>
                {
                    visitReport && visitReport.map((item) => {
                        return (
                            <div className='col-lg-6 col-12'>
                                <div className='d-flex flex-row gap-3'>
                                    <Typography.Text strong>{item.label}</Typography.Text>
                                    <Typography.Text>{item.value ? item.value : "N/A"}</Typography.Text>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default VisitDisplay
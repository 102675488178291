import { message, Typography } from 'antd';
import { useEffect, useState } from 'react';
import DataTable from '../../components/data-table/data-table.component';
import bioApi from '../../api/bioApi';
import { TREATMENT_NEED_MY_REVIEW_URL } from '../../api/URLs';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { treatmentPlanStatusDisplayMap } from '../../components/all-treatment-plans.component';

export const TreatmentsNeedMyReviewPage = () => {
  const [treatments, setTreatments] = useState([]);
  const [loading, setLoading] = useState(true);

  const tableSetting = {
    columns: [
      {
        title: 'Creation Date',
        dataIndex: 'createdDate',
        render: (createdDate) => moment(createdDate).format('YYYY-MM-DD hh:mma (z)'),
        sorter: (a, b) => moment(a.createdDate).unix() - moment(b.createdDate).unix(),
      },
      {
        title: 'Last Updated Date',
        dataIndex: ['statusHistory', '0', 'ts'],
        render: (d) => moment(d).format('YYYY-MM-DD hh:mma (z)'),
        sorter: (a, b) => moment(a.statusHistory[0].ts).unix() - moment(b.statusHistory[0].ts).unix(),
      },
      {
        title: 'Created By',
        dataIndex: ['creator', 'name'],
      },
      {
        title: 'Current Status',
        dataIndex: ['statusHistory', '0', 'status'],
        render: (status) => treatmentPlanStatusDisplayMap[status],
        filterable: true,
      },
      {
        title: 'Patient Name',
        dataIndex: 'patientName',
        render: (patientName, record) => <Link to={`../patient/${record.patientId}/treatment`}>{patientName}</Link>,
      },
    ],
  };

  useEffect(() => {
    (async () => {
      try {
        const treatmentsNeedMyReview = await bioApi.get(TREATMENT_NEED_MY_REVIEW_URL);
        setTreatments(treatmentsNeedMyReview.data);
      } catch (e) {
        message.error('Failed to load treatments');
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <div style={{ marginInline: 12 }}>
      <Typography.Title level={4}>Treatments Need My Review</Typography.Title>
      <DataTable data={treatments} settings={tableSetting} loading={loading} />
    </div>
  );
};

import {
  Col,
  Collapse,
  Descriptions,
  Modal,
  Row,
  Space,
  Typography,
} from 'antd';
import React, { useState } from 'react';
import { changePatientRequestStatus } from './service';
import moment from 'moment/moment';
import { TextField } from '@mui/material';
import "./patient-request.css"
import PatientRequestList from './components/patient-request.component';

const PatientRequestPage = () => {
  const [tableData, setTableData] = useState([]);
  const [statusChangeModal, setStatusChangeModal] = useState({
    show: false,
    record: null,
    to: '',
    display: <></>,
    note: '',
  });
  const [statusChanging, setStatusChanging] = useState(false);

  const changeStatus = async () => {
    setStatusChanging(true);
    await changePatientRequestStatus(statusChangeModal.record._id, statusChangeModal.to, statusChangeModal.note);
    const index = tableData.findIndex((d) => d._id === statusChangeModal.record._id);
    setTableData([
      ...tableData.slice(0, index),
      { ...tableData[index], status: statusChangeModal.to },
      ...tableData.slice(index + 1),
    ]);
    setStatusChanging(false);
    setStatusChangeModal({
      show: false,
      record: null,
      to: '',
      display: <></>,
      note: '',
    });
  };

  return (
    <div style={{ marginInline: 16 }}>
      <PatientRequestList all={true} listSize={10} />
      <Modal
        width={600}
        open={statusChangeModal.show}
        onCancel={() => setStatusChangeModal({ show: false, record: null, to: '', display: <></>, note: '' })}
        onOk={changeStatus}
        title={'Patient Request Status Change'}
        confirmLoading={statusChanging}
      >
        {statusChangeModal.record && (
          <>
            <Collapse
              items={[
                {
                  key: '1',
                  label: 'Request Detail',
                  children: (
                    <Descriptions bordered size={'small'} column={2}>
                      <Descriptions.Item label="Patient Name" span={1}>
                        {statusChangeModal.record.patientInfo.name}
                      </Descriptions.Item>
                      <Descriptions.Item label="Request Type" span={1}>
                        {statusChangeModal.record.type}
                      </Descriptions.Item>
                      <Descriptions.Item label="Request Time" span={2}>
                        {moment(statusChangeModal.record.creationTS).format('MMM DD, YYYY - hh:mma')}
                      </Descriptions.Item>
                      <Descriptions.Item label="Patient Comments" span={2}>
                        {statusChangeModal.record.comments}
                      </Descriptions.Item>
                    </Descriptions>
                  ),
                },
              ]}
            />
            <Row style={{ marginTop: 12 }} gutter={8}>
              <Col>
                <Typography.Text strong>*Status change: </Typography.Text>
              </Col>
              <Col>
                <Space>{statusChangeModal.display}</Space>
              </Col>
            </Row>
            <Row style={{ marginTop: 2, paddingInline: 8 }}>
              <TextField
                value={statusChangeModal.note}
                id="patient-request-note-input"
                label="Add Note"
                variant="standard"
                style={{ width: '100%' }}
                onChange={(e) => setStatusChangeModal({ ...statusChangeModal, note: e.target.value })}
              />
            </Row>
          </>
        )}
      </Modal>
    </div>
  );
};

export default PatientRequestPage;
